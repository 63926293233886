@import '../../../styles/design_system/colors.scss';

.candidate-menu-icon-container {
  margin-top: -12px;
}

#candidate-icon-menu {
  width: 30px;
  height: 1px;
  position: relative;
  margin: 5px auto;
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
  cursor: pointer;
}

#candidate-icon-menu span {
  display: block;
  position: absolute;
  height: 2px;
  width: 20px;
  background: $nepal;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: 0.25s ease-in-out;
}

#candidate-icon-menu span:nth-child(1) {
  top: 0px;
}

#candidate-icon-menu span:nth-child(2),
#candidate-icon-menu span:nth-child(3) {
  top: 6px;
}

#candidate-icon-menu span:nth-child(4) {
  top: 12px;
}

#candidate-icon-menu.open span:nth-child(1) {
  top: 18px;
  width: 0%;
  left: 50%;
}

#candidate-icon-menu.open span:nth-child(2) {
  transform: rotate(45deg);
  width: 24px;
}

#candidate-icon-menu.open span:nth-child(3) {
  transform: rotate(-45deg);
  width: 24px;
}

#candidate-icon-menu.open span:nth-child(4) {
  top: 18px;
  width: 0%;
  left: 50%;
}
