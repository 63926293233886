// Adapted from: https://github.com/maman/JVFloat.js/blob/master/jvfloat.css
.float-label {
  position: relative;
}

.float-label .placeholder {
  color: $lynx;
  font-size: 0.6rem;
  font-weight: 500;
  line-height: 11px;
  position: absolute;
  top: 0.5rem;
  left: 1rem;
  margin-bottom: 0px;
  -webkit-transform: translate(0, 0);
  -moz-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transition: -webkit-transform 150ms, opacity 100ms, visibility 100ms;
  transition: transform 150ms, opacity 100ms, visibility 100ms;
  opacity: 0;
  visibility: hidden;
  white-space: nowrap;
}

.float-label .placeholder.active {
  display: block;
  visibility: visible;
  -webkit-transform: translate(0, -3px);
  -moz-transform: translate(0, -3px);
  -o-transform: translate(0, -3px);
  -ms-transform: translate(0, -3px);
  transform: translate(0, -3px);
  -webkit-transition: -webkit-transform 100ms, opacity 120ms, visibility 120ms;
  transition: transform 100ms, opacity 120ms, visibility 120ms;
  opacity: 1;
  z-index: 5;
}

.input-group-append {
  border-radius: 0.25rem;
}

.label-datepicker {
  .float-label .placeholder {
    z-index: 200;
    transform: translate(0, 0);
    -webkit-transform: translate(0, 0);
    -moz-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
  }
  .float-label .placeholder.active {
    z-index: 200;
    transform: translate(0, -3px);
    -webkit-transform: translate(0, -3px);
    -moz-transform: translate(0, -3px);
    -o-transform: translate(0, -3px);
    -ms-transform: translate(0, -3px);
  }
}

.prepend-label-dollar {
  .float-label .placeholder {
    z-index: 200;
    transform: translate(34px, 0);
    -webkit-transform: translate(34px, 0);
    -moz-transform: translate(34px, 0);
    -o-transform: translate(34px, 0);
    -ms-transform: translate(34px, 0);
  }
  .float-label .placeholder.active {
    z-index: 200;
    transform: translate(34px, -3px);
    -webkit-transform: translate(34px, -3px);
    -moz-transform: translate(34px, -3px);
    -o-transform: translate(34px, -3px);
    -ms-transform: translate(34px, -3px);
  }
}

.prepend-label-slug {
  .float-label .placeholder {
    z-index: 200;
    transform: translate(150px, 0);
    -webkit-transform: translate(150px, 0);
    -moz-transform: translate(150px, 0);
    -o-transform: translate(150px, 0);
    -ms-transform: translate(150px, 0);
  }
  .float-label .placeholder.active {
    z-index: 200;
    transform: translate(150px, -3px);
    -webkit-transform: translate(150px, -3px);
    -moz-transform: translate(150px, -3px);
    -o-transform: translate(150px, -3px);
    -ms-transform: translate(150px, -3px);
  }
}

.prepend-label-twitter {
  .float-label .placeholder {
    z-index: 200;
    transform: translate(38px, 0);
    -webkit-transform: translate(38px, 0);
    -moz-transform: translate(38px, 0);
    -o-transform: translate(38px, 0);
    -ms-transform: translate(38px, 0);
  }
  .float-label .placeholder.active {
    z-index: 200;
    transform: translate(38px, -3px);
    -webkit-transform: translate(38px, -3px);
    -moz-transform: translate(38px, -3px);
    -o-transform: translate(38px, -3px);
    -ms-transform: translate(38px, -3px);
  }
}

.prepend-label-facebook {
  .float-label .placeholder {
    z-index: 200;
    transform: translate(121px, 0);
    -webkit-transform: translate(121px, 0);
    -moz-transform: translate(121px, 0);
    -o-transform: translate(121px, 0);
    -ms-transform: translate(121px, 0);
  }
  .float-label .placeholder.active {
    z-index: 200;
    transform: translate(121px, -3px);
    -webkit-transform: translate(121px, -3px);
    -moz-transform: translate(121px, -3px);
    -o-transform: translate(121px, -3px);
    -ms-transform: translate(121px, -3px);
  }
}

.prepend-label-sftp {
  .float-label .placeholder {
    z-index: 200;
    transform: translate(64px, 0);
    -webkit-transform: translate(64px, 0);
    -moz-transform: translate(64px, 0);
    -o-transform: translate(64px, 0);
    -ms-transform: translate(64px, 0);
  }
  .float-label .placeholder.active {
    z-index: 200;
    transform: translate(64px, -3px);
    -webkit-transform: translate(64px, -3px);
    -moz-transform: translate(64px, -3px);
    -o-transform: translate(64px, -3px);
    -ms-transform: translate(64px, -3px);
  }
}
