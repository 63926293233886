.winred-dropdown-container {
  font-size: 27px;

  .dropdown-menu {
    border: 1px solid $catskill;
    padding: 0px;
    box-shadow: 5px 5px 20px rgba(30, 43, 58, 0.05);

    .dropdown-item {
      color: $mirage;
      font-weight: 500;
      padding: 1rem;

      &:hover {
        background: $catskill-white;
      }
    }
  }

  .dropdown {
    width: 17px;
    height: 32px;

    .btn-primary.dropdown-toggle {
      color: #ffffff;
      background-color: transparent;
      border-color: transparent;
    }
  }

  .custom-dropdown-toggle {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border: 0;
    cursor: pointer;
    width: 17px;
    height: 32px;
    outline: none;
    box-shadow: none;

    &::after {
      visibility: hidden;
    }

    &:active {
      background-color: transparent;
      border-color: transparent;
      border: 0;
      outline: none;
      box-shadow: none;
      border-style: none;
      box-shadow: none !important;
    }

    &:focus {
      background-color: transparent;
      border-color: transparent;
      border: 0;
      outline: none;
      box-shadow: none;
      border-style: none;
      box-shadow: none !important;
    }
  }

  .custom-dropdown-toggle.pickled-bluewood-drop-down.dropdown-toggle {
    color: $pickled-bluewood,
  }

  .custom-dropdown-toggle.lynx-drop-down.dropdown-toggle {
    color: $lynx,
  }

  svg {
    width: 17px !important;
    height: 32px !important;
  }
}
