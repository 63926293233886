
.add-card {
    padding: 1.5rem;

    .info-icon {
        @media (min-width: 1188px) {
            max-width: 65px;
        }
    }

    .icon {
        width: 35px;
        height: 35px;
        position: relative;
        top: 5px;
    }

    .id {
        font-size: 12px;
        letter-spacing: -0.25px;
    }

    .p--medium {
        font-weight: 500;
        font-size: 14px;
    }

    p {
        margin-bottom: 0rem;
    }
}


@media (max-width: 575px) {
    .add-card {
        padding: 1rem;
    }
}

.double-add-list-items {
    > div > div {
        display: flex;
        flex-direction: row;
    }

    .add-card:first-child  {
        margin-right: 0.75rem;
    }

    .add-card:last-child  {
        margin-left: 0.75rem;
    }
}

@media (max-width: 575px) {
    .double-add-list-items {

        > div > div {
            display: flex;
            flex-direction: column;
        }

        .add-card:first-child  {
            margin-right: 0rem;
            margin-bottom: 1rem;
        }

        .add-card:last-child  {
            margin-left: 0rem;
        }
    }
}