/*** Icons ***/
.winred-btn {
  color: $white;
  border-radius: 50px;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  white-space: normal;
  height: fit-content;

  &:disabled {
    color: white;
    background: $nepal;
    border-color: $nepal;
  }
}

/* Modifiers */
.winred-btn--primary {
  background-color: $winred-blue-light;
  border-color: $winred-blue-light;
  &:hover:not([disabled]),
  &:focus:not([disabled]),
  &:active:not([disabled]) {
    background-color: $winred-blue-hover !important;
    border-color: $winred-blue-hover !important;
    color: $white !important;
  }

  &:disabled {
    color: $white !important;
    background-color: $winred-blue !important;
    border-color: $winred-blue !important;
  }
}

.winred-btn--secondary {
  background-color: $winred-green;
  border-color: $winred-green;
  color: $white;
  &:hover:not([disabled]),
  &:focus:not([disabled]),
  &:active:not([disabled]) {
    background-color: $winred-green-hover !important;
    border-color: $winred-green-hover !important;
    color: $white !important;
  }
}

.winred-btn--alt {
  color: $lynx;
  background-color: $white;
  border: 1px solid $mystic;

  &:hover:not([disabled]),
  &:focus:not([disabled]),
  &:active:not([disabled]) {
    background-color: $white !important;
    border: 1px solid $nepal !important;
    color: $lynx !important;
  }
}

.winred-btn--cancel,
.winred-btn--error {
  background-color: $winred-red;
  &:hover:not([disabled]),
  &:focus:not([disabled]),
  &:active:not([disabled]) {
    background-color: $winred-red-light !important;
    border-color: $winred-red-light !important;
    color: $white !important;
  }

  &:disabled {
    color: white;
    background: $geraldine;
    border-color: $geraldine;
  }
}

.btn-outline-primary {
  color: $lynx;
  background-color: white;
  border: 1px solid $mystic;

  svg {
    color: $lynx;
  }

  &:hover,
  &:active,
  &:focus,
  &:focus-within,
  &[aria-expanded='true'] {
    color: $lynx !important;
    background-color: $white !important;
    border-color: $nepal !important;
    box-shadow: none !important;

    svg {
      color: $lynx !important;
    }
  }
}

.winred-btn--pro {
  background-color: $mirage;
  &:hover:not([disabled]) {
    background-color: $pickled-bluewood-light;
    border-color: $nepal;
    color: $white;
  }
}

.winred-btn--orange {
  background-color: $rajah;
  color: white !important;
  &:hover:not([disabled]) {
    background-color: $banana;
    border-color: $banana;
    color: $white;
  }
}

.winred-btn--alt--success {
  color: $lynx;
  border: 1px solid $jagged-ice;
  background-color: $white-ice;
  &:hover:not([disabled]) {
    border-color: $algae-green;
    background-color: $ice-white;
    color: $lynx;
  }
}

.winred-btn--alt--cancel,
.winred-btn--alt--error {
  color: $lynx;
  border: 1px solid $oyster-pink;
  background-color: $rose-white;
  &:hover:not([disabled]) {
    background-color: #fff1f2;
    border: 1px solid #f1adb5;
    color: $lynx;
  }

  &:active {
    background-color: #f1adb5 !important;
    border: 1px solid #f1adb5 !important;
  }
}

.winred-btn--alt--orange {
  color: $lynx;
  border: 1px solid $pearl-bush;
  background-color: $romance;
  &:hover:not([disabled]) {
    border: 1px solid #f1adb5;
    background-color: #fffaf3;
    color: $lynx;
  }
}

.winred-btn--alt--toggle {
  color: $lynx;
  border: 1px solid $mystic;

  &:hover:not([disabled]),
  &:focus:not([disabled]),
  &:active {
    transition: 0s;
    color: $lynx;
    background-color: $white;
    border-color: $nepal;
    box-shadow: none !important;

    svg {
      color: $lynx;
    }
  }
}

.winred-btn {
  &:disabled {
    color: white;
    background: $nepal;
    border-color: $nepal;
    cursor: default;
  }
}
