html {
  scroll-behavior: smooth;
}

.smooth-scroll {
  transition: all 0.5s ease-in-out;
  scroll-margin-top: -300px;
}

.smooth-scroll:focus {
  outline: none;
}

.smooth-scroll:focus,
.smooth-scroll:active {
  transform: translateY(-100px);
}