.team-donations-table {
  td {
    max-width: 400px !important;
  }
  tbody {
    tr:hover {
      background-color: inherit !important;
      color: inherit !important;
    }
  }
}
