@import 'border.scss';
@import 'text.scss';
@import 'overflow.scss';
@import 'shadows.scss';
@import 'sizes.scss';
@import 'text.scss';

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 500;
}

$font-size-base: 1rem !default;

$h1-font-size: $font-size-base * 2.5 !default;
$h2-font-size: $font-size-base * 2 !default;
$h3-font-size: $font-size-base * 1.75 !default;
$h4-font-size: $font-size-base * 1.5 !default;
$h5-font-size: $font-size-base * 1.25 !default;
$h6-font-size: $font-size-base !default;

h1,
.h1 {
  @include font-size($h1-font-size);
}
h2,
.h2 {
  @include font-size($h2-font-size);
}
h3,
.h3 {
  @include font-size($h3-font-size);
}
h4,
.h4 {
  @include font-size($h4-font-size);
}
h5,
.h5 {
  @include font-size($h5-font-size);
}
h6,
.h6 {
  @include font-size($h6-font-size);
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
  display: initial;
}
