@import '../../../../styles/design_system/colors.scss';

.bulk-page-drawer {
  background-color: $catskill-white !important;
}
.page-template-drawer {
  background-color: $catskill-white !important;
}

.label-status {
  border-width: 1px;
}

.duplicate-results-header {
  background-color: $catskill-white !important;
}

.slug-error-row {
  &:hover {
    background: inherit !important;
  }
}
