$boxShadowColors: (
  'catskill': $catskill,
  'winred-red': $winred-red,
  'winred-blue-light': $winred-blue-light,
  'nepal': $nepal,
  'pickled-bluewood-light': $pickled-bluewood-light,
  'pickled-bluewood': $pickled-bluewood,
);

@each $name, $color in $boxShadowColors {
  .box-shadow-inset-#{'' + $name} {
    box-shadow: inset 0 -2px 0 0 $color;
  }
}
