@import '../../../styles/design_system/colors.scss';

.dashboard-avatar-container {
  width: 50px;
  height: 50px;
}

.dashboard-connected-title {
  font-size: 16px;
}

.dashboard-header {
  .refresh-data-dashboard {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  @media (max-width: 992px) {
    padding-right: 30px;
    padding-left: 30px;
  }

  @media (max-width: 575px) {
    & > div {
      padding: 0 0.5rem;
    }

    .refresh-data-dashboard {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding-left: 0;
      margin-top: 1rem;
    }
  }
}

.dashboard-graph {
  .reporting-trends-bar {
    .trends-divider {
      border-right: 1px solid $catskill;
    }

    .no-change {
      margin-top: 0.3rem;
      > div {
        margin-bottom: 0.3rem;
      }
    }

    .dashboard-changes {
      border-radius: 7px;
    }

    .dashboard-green {
      color: $winred-green;
      background-color: $jagged-ice;
    }

    .dashboard-red {
      color: $winred-red;
      background-color: $oyster-pink;
    }
  }

  .reporting-button {
    padding: 0.7rem !important;
    font-weight: bold;
  }

  .nav-item {
    box-shadow: inset 0 -2px 0 0 $catskill;
  }

  .winred-nav__nav-item--active {
    box-shadow: inset 0 -2px 0 0 $winred-red;
  }
}

.dashboard-committee-pages {
  .cp-total {
    color: $winred-green;
  }

  .cp-item {
    border-top: 1px solid $mystic;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .cp-number {
    font-size: 1.5rem;
  }

  .cp-amount,
  .cp-name {
    font-size: 1rem;
  }
}
