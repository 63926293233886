@import '../../../styles/design_system/colors.scss';

.table-input-container  { 
  margin-bottom: 10px;
}

.input-remove-btn {
  display: flex;
  justify-content: flex-end;
  padding-left: 0px;
  padding-top: 0.9rem;
}

.input-move-btn {
  display: flex;
  justify-content: flex-end;
  padding-left: 0px;
  padding-top: 0.9rem;
}

@media (max-width: 992px) {
  .input-remove-btn {
    width: 100%;
    padding-top: 0rem;
  }

  .input-move-btn {
    display: none;
  }

  .table-input-container {
    border-bottom: 1px solid $catskill;
    margin-bottom: 1rem;

    .table-input {
      order: 2;
      margin-bottom: 1rem;

      .float-label {
        p {
          margin: 0 !important;
        }
      }
    }

    .input-remove-btn {
      order: 1;
      margin-top: 1rem;
      margin-bottom: 0.5rem;
    }
  }
}
