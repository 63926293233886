@import '../../styles/design_system/colors.scss';

.winred-chip {
  width: fit-content;
  // Tailwind styles clash with bootstrap styles
  padding: 0.3rem 0.8rem 0.3rem 0.8rem;
  color: black;
  border: 1px solid black;
  display: flex;
  align-items: center;
}

.winred-chip__red {
  background-color: $rose;
  color: $winred-red;
  border-color: $winred-red;
}

.winred-chip__green {
  border-color: $sea-green;
  color: $sea-green;
  background-color: $ice-white;
}

.winred-chip__yellow {
  border-color: $coral;
  color: $coral;
  background-color: $banana;
}
