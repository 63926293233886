@import '../../styles/variables.scss';

.footer-container {
  margin-top: 1rem;
  .footer-col {
    padding-left: 0;
    padding-right: 0;
    background-color: #e9ebef;
    font-weight: 400;

    @media (max-width: 575px) {
      padding: 20px 0px;
    }
  }

  .footer-links {
    display: flex;
    align-items: center;

    .footer-item {
      cursor: pointer;
      padding: 27px 18px;

      img {
        height: 24px;
      }

      @media (max-width: 575px) {
        padding: 10px;
      }
    }

    a {
      color: $pickled-bluewood-light;
      text-decoration: none;
    }
  }
}

.mobile-fix-padding {
  @media (max-width: 992px) {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
}

.layout-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
