select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-image: url('../assets/images/chevron-down.svg');
  background-repeat: no-repeat;
  background-position: right 1rem top 48%;
  background-size: 14px;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

input[type='number'] {
  -moz-appearance: textfield;
}

// Remove double X on clear inputs on Edge and IE //
input::-ms-clear {
  display: none;
}
