@import '../../../styles/design_system/colors.scss';

.predicate-btn {
  .btn {
    padding: 10px 15px;
  }
}

.winred-date-picker-full-width {
  width: 100%;

  .react-datepicker-wrapper {
    width: 100%;

    .react-datepicker__input-container {
      width: 100%;

      input {
        font-family: Roboto, sans-serif !important;
        color: $winred-blue;
        font-weight: 500;

        &::placeholder {
          color: $winred-blue;
        }
      }
    }
  }
}

.form-card {
  margin-top: 1rem;

  .card-body {
    padding-bottom: 0.25rem;
  }
}

.winred-binary-choice {
  .winred-btn--primary:disabled {
    color: $white;
    background-color: $winred-blue;
    border-color: $winred-blue;
  }
}

.winred-slider-container {
  display: flex;

  .input-group-text {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  .winred-slider {
    border: 1px solid $mystic;
    padding-left: 1.2rem !important;
    padding-right: 1.2rem !important;
    padding-top: 0.3rem !important;
    padding-bottom: 0.3rem !important;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    flex-grow: 1;

    *:focus {
      outline: none;
    }

    .slider-value {
      color: $white;
      font-size: 10px;
    }
  }
}

.toggle-off {
  transform: rotateY(180deg);

  > g {
    transform: translateX(45%) translateY(50%);
  }
}
