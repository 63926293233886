@import '../../styles/design_system/colors.scss';

.donation-trends,
.subscriptions-trends {
  margin-top: -10px;
  display: flex;

  .divider {
    margin-top: -2px;
  }
}

.first-layer {
  display: flex;
  align-items: center;
  width: 100%;
  padding-left: 3.1em;
  background-color: $catskill-white;
  .list-group-item {
    border-top: 0;
    width: 100%;
    background-color: $catskill-white;
  }
}
