@import '../../../styles/design_system/colors.scss';

.preview-card {
  padding-bottom: 1.7rem;
}

.widget-preview_multiplier {
  padding-left: 0;
  padding-right: 0;

  .multiplier-text-amt {
    font-weight: 600;
  }

  .multiplier-text {
    text-align: center;
    p {
      margin-bottom: 0.3rem;
    }

    > div {
      font-size: 1.6rem;
    }
    border: 1px solid $mystic !important;
    border-radius: 0.25em;
    padding: 1rem;

    @media (max-width: 575px) {
    }
  }
}

.widget-preview_shotclock,
.widget-preview_countdown,
.widget-preview_midnight {
  > p {
    text-align: center;
    margin-top: 1.3rem;
    margin-bottom: 0.5rem;
  }

  .countdown-numbers {
    display: flex;
    div {
      padding: 0.7rem;
      background-color: black;
      color: white;
      margin-right: 0.3rem;
      margin-left: 0.3rem;
      font-size: 2rem;
      border-radius: 0.15em;
      font-weight: 500;
    }
  }

  .countdown-seperator {
    padding-top: 0.4rem;
    font-size: 2rem;
    margin-right: 0.5rem;
    margin-left: 0.5rem;
  }

  .countdown-label {
    border: 0px;
    background-color: transparent;
    text-align: center;
    color: $lynx;
    text-transform: uppercase;
    white-space: nowrap;
    font-weight: 500;
  }
}

.widget-preview_midnight,
.widget-preview_countdown {
  .countdown-numbers {
    div {
      padding: 0.4rem;
      font-size: 1.4rem;
      margin-right: 0.2rem;
      margin-left: 0.2rem;
    }
  }

  .countdown-seperator {
    padding-top: 0rem;
    margin-right: 0.1rem;
    margin-left: 0.1rem;
  }

  .countdown-label {
    font-size: 0.7rem;
  }
}

.widget-preview_social {
  .social-link {
    border-radius: 0.25em;
    text-align: center;
    color: $white;
    padding: 0.8rem 1rem 0.8rem 1rem;
    box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.3);
    transition: box-shadow 0.3s ease-in-out;
    cursor: pointer;

    a {
      color: $white;
      font-weight: 500;
      font-size: 1rem;
      text-decoration: none;

      &:link,
      &:visited,
      &:hover,
      &:active {
        color: $white;
      }
    }

    &:hover {
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
    }
  }

  .twitter-link {
    background-color: #1da1f2;
  }

  .facebook-link {
    background-color: #4267b2;
    margin-bottom: 1rem;
  }
}

.widget-preview_popup {
  .widget-popup {
    border-radius: 0.25em;
    text-align: center;
    padding: 0;
    box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.3);
    transition: box-shadow 0.3s ease-in-out;

    .widget-popup-image {
      max-width: 100%;
      padding: 1rem 2rem;

      img {
        width: 100%;
        height: auto;
      }
    }

    .widget-popup__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: $white;
      font-weight: 500;
      padding: 0.8rem 1rem 0.8rem 1rem;
      font-size: 1.2rem;
      text-transform: uppercase;

      svg {
        cursor: pointer;
      }
    }
    .widget-popup__body {
      padding: 1.5rem 1rem;
      margin-bottom: 1rem;
      text-align: left;
      font-size: 1rem;
    }
    .widget-popup__footer {
      padding: 0.8rem 1rem 0.8rem 1rem;
      border-top: 1px solid $mystic;

      .btn {
        border-radius: 0.25em;
        border: none;
        font-size: 1rem;
        text-transform: uppercase;
      }
    }
  }
}

.widget-preview_donation {
  .widget-donation {
    width: 100%;
    white-space: nowrap;
    font-size: 1.1rem;
  }

  .widget-donation__placeholder {
    font-weight: 400;
    font-size: 16px;
    color: black;
  }

  .widget-donation__custom-copy {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
    border-radius: 0.25rem;
    font-weight: 500;
  }

  .widget-donation__money {
    color: #6291ff;
    font-weight: 500;
  }
}

.widget-button-animation {
  .widget-button-animation_footer {
    background-color: $catskill-white;
  }
}

.widget-preview_btnAnimation {
  .widget-preview_btnAnimation_btn-other {
    width: 100px;
    margin-left: 0.3rem;
    margin-right: 0.3rem;
  }

  .widget-preview_btnAnimation_btn-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    button {
      margin-left: 0.3rem;
      margin-right: 0.3rem;
      margin-bottom: 0.5rem;
      border-radius: 5px;
      border: none;
      width: 75px;
    }
  }
}

.widget-drawer {
  background-color: $catskill-white;
  overflow-x: hidden;
}

.color-picker-row {
  margin: 0.5rem;
}

.poll-results-color {
  color: #354b70 !important;
}
