@import '../../../../styles/design_system/colors.scss';

.winred-dropdown {
  .disabled {
    background-color: $catskill-white !important;
  }

  .winred-dropdown-icon {
    min-width: 40px;
  }

  .icon-dropdown {
    padding: 16px 20px 16px 14px;
    border-left: 1px solid #627890;
  }

  .dropdown-left-btn {
    padding-left: 18px;
    padding-right: 14px;
  }

  button::after {
    display: none;
  }

  .dropdown-menu {
    border: 1px solid $catskill;
    border-radius: 4px;
    background-color: white;
    padding: 0rem;
    box-shadow: 5px 5px 20px rgba(30, 43, 58, 0.1);
  }

  .dropdown-item {
    background-color: transparent;
    padding: 1rem;
    color: $mirage;

    &:active,
    &:disabled {
      color: $mirage;
    }

    &:hover {
      background-color: $catskill-white;
    }
  }
}
