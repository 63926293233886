@import 'variables';

.input-group-prepend .input-group-text, .input-group-append .input-group-text {
  background-color: $catskill-white;
  padding-left: 12px;
  padding-right: 12px;
  border-color: $mystic;
  color: $lynx;
}

.form-control {
  &:focus {
    color: $winred-blue;
    border-color: $nepal;
    box-shadow: none;
  }
}

.close-btn {
  transform: translateX(-50%) translateY(-50%);
  top: 50%;
  right: 10px;
  z-index: 10;
}

.top-divider {
  border-top: 1px solid $mystic;
}
