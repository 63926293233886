@import '../../../styles/design_system/colors.scss';

.upsell-drawer {
  .upsell-card {
    min-height: 117px;
  }

  .upsell-card-image {
    img,
    svg {
      height: 2rem;
      width: 2rem;
    }
  }

  .upsell-selected {
    background-color: $catskill-white;
  }

  .new-flow-form {
    background-color: $catskill-white;
  }

  .new-upsell-footer {
    padding: 1rem;
  }

  .decline-form-group {
    margin-bottom: 0px;
  }

  .accept-form-group {
    @media (min-width: 576px) {
      margin-bottom: 0px;
    }
  }
}

.no-upsells-in-group-error {
  padding-bottom: 1rem;
  text-align: center;
}
