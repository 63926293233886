@import '../../styles/variables.scss';

.toast-wrapper {
  z-index: 90000;
  position: fixed;
  left: 0;
  right: 0;
  text-align: 'center';

  &.success {
    background-color: #f7fefa;
    color: map-get($theme-colors, 'success');
    border-color: #ddf3ea;
  }

  &.warning {
    background-color: #fefbf9;
    color: map-get($theme-colors, 'orange');
    border-color: #e6dacb;
  }

  &.error {
    background-color: #fef7fa;
    color: map-get($theme-colors, 'error');
    border-color: #f3ddea;
  }

  &.toast-top {
    top: 0;
    border-bottom-width: 1px;
    border-bottom-style: solid;
  }

  &.toast-bottom {
    bottom: 0;
    border-top-width: 1px;
    border-top-style: solid;
  }

  .toast-contents {
    padding: 1rem;
    text-align: center;
    font-size: 0.85rem;
    font-weight: 500;
  }

  .toast-close-icon {
    cursor: pointer !important;
    z-index: 10000;
    float: right;
  }
}

.persistent-toast {
  background-color: #fef7fa;
  color: map-get($theme-colors, 'error');
  border-bottom: 1px solid #eedddf;

  .toast-contents {
    padding: 1rem;
    text-align: center;
    font-size: 0.85rem;
    font-weight: 500;
  }
}
