@import '../../../styles/design_system/colors.scss';

.trends-bar {
  background-color: $white;
  border-bottom: 2px solid $catskill;

  .trend-box {
    padding: 1rem;
  }

  .trends-divider {
    @media (min-width: 576px) {
      border-right: 1px solid $catskill;
    }
  }
}

.accounting-payout-trends {
  .trends-divider {
    @media (max-width: 1309px) {
      border-right: none !important;
    }
  }

  .trend-box {
    padding: 1rem;

    @media (max-width: 1309px) {
      width: inherit;
      max-width: 25%;
    }

    @media (max-width: 790px) {
      width: inherit;
      max-width: 50%;
    }
  }
}

@media (max-width: 1309px) {
  .double-trends {
    width: 100%;
    flex-wrap: wrap;
  }
}

.divider {
  height: 100%;
  width: 2px;
  background-color: $catskill;
  margin-left: 1rem;
  margin-right: 1rem;
}