@import '../../../../styles/design_system/colors.scss';

.expanded-item-label {
  font-size: 14px;
}

.d-md-table-row {
  cursor: pointer;
}

.no-pointer {
  cursor: default;
}

tr:focus {
  outline: none;
}

.expanded-item-value {
  font-size: 14px;
  font-weight: 400;
  color: $lynx;
  padding-bottom: 1.1rem;
  overflow-wrap: anywhere;
}

.table td.skinny-button-td {
  padding: 1rem 0rem 1.45rem 1.1rem;
}

.winred-table-expanded-container {
  background-color: $catskill-white !important;
  color: black;
  cursor: default;

  td {
    width: 100%;
  }

  &:hover {
    background-color: $catskill-white;
  }
}

.no-hover-table {
  background-color: $white;
  color: black;

  > tbody > tr:not(.winred-table-expanded-container):hover {
    background-color: $white;
  }
}


.row-expand-icon {
  padding-right: 10px !important;
}