.scroll-x {
  overflow-x: auto;
}

.scroll-y {
  overflow-y: auto;
}

.overflow-hidden-x {
  overflow-x: hidden;
}

.overflow-hidden-y {
  overflow-y: hidden;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  display: block;
}
