@import '../../../../styles/design_system/colors.scss';

.nested-drawer-open-back {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  background-color: $mirage;
  opacity: 0.5;
}

.double-nested-width-back {
  z-index: 18999;
}

.nested-width-back {
  z-index: 16999;
}

.full-width-back {
  z-index: 14999;
}

.double-nested-width {
  z-index: 19000;
  max-width: 858px;
}

.nested-width {
  z-index: 17000;
  max-width: 925px;
}

.full-width {
  z-index: 15000;
  max-width: 992px;
}

.drawer-padding {
  padding: 1rem;
}

.nested-drawer {
  position: fixed;
  top: 0;
  height: 100%;
  width: 100%;
  padding-bottom: 1rem;
  display: flex;
  flex-direction: column;

  box-shadow: 0px 10px 28px -7px $catskill;
  background-color: $white;
  transition-timing-function: ease-in;
  transition: 0.2s;
  overflow-y: scroll;

  .nested-drawer-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
  .nested-drawer-footer {
    position: sticky;
    bottom: -1rem;
    background-color: white;
    border: 1px solid $catskill;
    z-index: 15001;
    margin-top: 1rem;
    margin-left: -1rem;
    margin-bottom: -1rem;
    margin-right: -1rem;
    flex-shrink: 0;
  }

  @media (max-width: 767px) {
    overflow-x: hidden;
    padding: 1rem 0;
  }

  &.nested-drawer-right {
    right: 0;
    transform: translateX(130%);
  }

  &.nested-drawer-left {
    left: 0;
    transform: translateX(-130%);
  }

  &.open {
    transition-timing-function: ease-out;
    transform: translateX(0);
  }
}

.nested-drawer-filter {
  position: fixed;
  top: 0;
  z-index: 15000;

  .nested-drawer {
    .nested-drawer-filter_section {
      border: 1px solid $catskill;
      margin-bottom: 1rem;
      border-radius: 0.25rem;

      .form-label {
        font-weight: 500;
      }

      .nested-drawer-filter_body {
        background-color: $catskill-white;
        padding: 1rem 1.5rem 0.5rem;
        text-align: left;

        @media (max-width: 576px) {
          padding: 1rem 1rem 0rem;
        }
      }
    }
  }
}
