@import 'variables';

.winred-nav {
  &.fill {
    justify-content: space-between;
  }

  .dropdown-toggle {
    &::after {
      border: unset;
    }

    &.active {
      color: $primary;
    }
  }

  .dropdown-item {
    .winred-nav__nav-item--active:not(:hover) {
      box-shadow: none;
    }

    .winred-nav__nav-link {
      color: inherit;
    }
  }
}
