.refresh-data {
  display: flex;
  align-items: center;
  font-size: 12px;

  button {
    margin-right: 0.5rem;
    padding: 0.6rem 0.6rem;
    color: #333;

    span {
      &:hover:not([disabled]) {
        color: #333 !important;
      }
    }
  }
}
