@import '../../../styles/design_system/colors.scss';

.winred-chart {
  circle {
    fill: $white;
    stroke: $jagged-ice;
  }
}

.trends-label {
  border: 0px;
  background-color: transparent;
  text-transform: uppercase;
  font-weight: 500;
  color: $nepal;
  font-size: 12px;
  letter-spacing: 2px;
  line-height: 21px;
}
