@import '../../../../styles/design_system/colors.scss';


.candidiate-drawer-list {
  .agency-container {
    background-color: $catskill-white;
    svg {
      margin-left: 1.6rem;
    }
  }

  .selected-candidate {
    color: $white !important;
    border: $winred-red-dark;
    background: linear-gradient(90deg, #5a2635 0%, #7f2b3d 100%);
    p,
    svg {
      color: $white !important;
    }
  }

  .unselected-candidate {
    &:hover {
      background-color: $mystic;
      color: #000 !important;

      p {
        color: #000 !important;
      }
    }
  }
}

.candidate-drawer-footer {
  border-top: 1px solid $mystic;
}

.candidate-menu-footer {
  box-shadow: inset 0 -2px 0 0 $catskill;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}
