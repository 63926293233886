@import '../../../styles/design_system/colors.scss';

.header-subheader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .list-header-refresh {
    display: flex;
    justify-content: center;
    order: 2;
    margin-left: 0px;
  }

  .lonely-header-refresh {
    padding-bottom: 1rem;
  }

  .bottom-bar {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .winred-nav {
    box-shadow: none;
  }

  .nav-list {
    order: 1;
  }
}

@media (max-width: 768px) {
  .header-subheader {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;

    .nav-list {
      order: 5;
    }

    .list-header-refresh {
      justify-content: flex-start;
      margin-left: -10px;
      order: 1;
    }
    .bottom-bar {
      flex-direction: column;
      .refresh-data {
        margin-top: 1rem;
      }
    }
  }
}

@media (max-width: 991px) {
  .nav-list {
    &::-webkit-scrollbar {
      height: 0px !important;
    }

    .winred-nav .winred-nav__nav-item {
      &:hover {
        box-shadow: inset 0 -2px 0 0 $nepal;
      }
    }

    .winred-nav .winred-nav__nav-item--active {
      box-shadow: inset 0 -2px 0 0 $winred-red;

      &:hover {
        box-shadow: inset 0 -2px 0 0 $winred-red;
      }
    }

    .nav-link {
      padding: 1rem;
    }

    .winred-nav {
      flex-wrap: nowrap;
      box-shadow: none;
    }

    .page-header {
      box-shadow: none;
    }
  }
}

.list-button-alignment {
  margin-top: -0.25em;
}
