@import './colors.scss';

/*** Typography ***/
.winred-h1 {
  font-size: 36px;
  line-height: 48px;
  letter-spacing: -0.75px;
  text-transform: capitalize;
  word-break: break-word;
  &.pro {
    color: white;
  }
}

.winred-h2 {
  font-size: 25px;
  line-height: 33px;
  letter-spacing: -0.52px;
  &.pro {
    color: white;
  }
}

.winred-h4 {
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.38px;
  &.pro {
    color: white;
  }
}

.winred-h5 {
  font-size: 17.5px;
  line-height: 1.2;
  font-weight: 500;
  &.pro {
    color: white;
  }
}

.p {
  font-size: 14px;
  color: $lynx;
  font-weight: 400;
  line-height: 1.4;
  &.pro {
    color: white;
  }
}

.tiny {
  font-size: 11px;
  font-style: italic;
}

.p--medium {
  font-size: 14px;
  font-weight: 500;
}

.winred-table-header {
  border: 0px;
  background-color: transparent;
  padding: 0rem 0rem 1rem 1.1rem;
  text-transform: uppercase;
  white-space: nowrap;
  font-weight: 500;
  color: $lynx;
  font-size: 10px;
  line-height: 1.5rem;
  letter-spacing: 2px;
}
