.qr-code-modal {
  width: 70% !important;

  .modal-header {
    svg {
      width: 0.6em;
    }

    button {
      margin-top: -3px !important;
    }
  }

  .modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 0px !important;

    .slug-container {
      margin-top: 0rem;
      display: flex;
      flex-direction: column;
      align-items: center;

      text-align: center;
      width: 75%;

      @media (max-width: 493px) {
        width: 100%;
      }
    }

    img {
      max-width: 250px;

      @media (max-width: 493px) {
        max-width: 100%;
      }
    }
  }

  .modal-footer {
    justify-content: space-between;
    padding-left: 1rem !important;
    padding-right: 1rem !important;

    button, a {
      margin-right: 0 !important;
      width: unset;

      @media (max-width: 493px) {
        width: 100%;
      }
    }
  }
}
