/*** Containers ***/
/* Blocks */
.winred-container {
  background-color: $white;
  border: 1px solid $catskill;
  border-radius: 4px;
}
// Bootstrap overrides
.card-footer {
  background-color: $white;
  padding: 1.5rem;

  @media (max-width: 575px) {
    padding: 1rem;
  }

  &:last-child {
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
  }
}

/* Modifiers */
.winred-container--alt {
  background-color: $catskill-white;
}

.winred-container--blue {
  background-color: #fbfbfe;
  border: 1px solid #d9e1e8;
}

.winred-container--green {
  background-color: #fafdfb;
  border: 1px solid rgb(203, 229, 208);
}

.winred-container--red {
  background-color: $rose-white;
  border: 1px solid $oyster-pink !important;

  p {
    color: $winred-red-light;
  }

  svg {
    color: $winred-red-light;
  }
}

.winred-container--gold {
  background-color: $romance;
  border: 1px solid $pearl-bush;
}

.winred-container--gray {
  background-color: #fcfdfd;
  border: 1px solid $mystic;
}

.winred-container--upload {
  border: 1px dashed $mystic;
  background-color: transparent;

  img {
    width: 100%;
    max-width: 200px;
  }
}

// .winred-container--pro {
//   background-color: $pickled-bluewood-light;
//   border: 1px solid $pickled-bluewood-light;
// }

// .winred-container--pro--alt {
//   background-color: $pickled-bluewood;
//   border: 1px solid $pickled-bluewood-light;
// }

// .winred-container--pro--upload {
//   border: 1px dashed $nepal;
//   background-color: $pickled-bluewood;
// }

// .winred-container--pro--green {
//   border: 1px solid $sea-green;
//   background-color: $plantation;
// }

// .winred-container--pro--red {
//   border: 1px solid $hippie-pink;
//   background-color: $matterhorn;
// }

// .winred-container--pro--gold {
//   border: 1px solid $whiskey;
//   background-color: $soya-bean;
// }
