@import '../../../styles/design_system/colors.scss';

.info-card-body {
  padding: 1.5rem;
  text-decoration: none;

  &:hover {
    text-decoration: none !important;
  }

  .p--medium {
    font-weight: 500;
    font-size: 16px;
  }

  p {
    margin-bottom: 0rem;
    &:hover {
      text-decoration: none !important;
    }
  }

  .info-card-icon {
    @media (min-width: 1188px) {
      max-width: 65px;
    }
  }

  .icon {
    width: 35px;
    height: 35px;
    position: relative;
    top: 3px;
  }

  .info-card-details {
    .id {
      font-size: 12px;
      letter-spacing: -0.25px;
      color: $lynx;
    }

    .date {
      font-size: 12px;
      letter-spacing: -0.25px;
      color: $lynx;
    }

    .p--medium {
      color: $winred-blue;
    }
  }
}
