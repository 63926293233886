@import 'variables';

.list-item-card {
  padding: 1rem;
  background-color: $table-bg;
  border-top: $border-width solid $border-color;
  color: $lynx;
  word-wrap: break-word;
  outline: 0;

  .list-item-card-icon {
    font-size: 20px;
    max-width: 35px;
  }

  .list-item-card-icon-mobile {
    div {
      outline: none;
    }
    svg {
      margin-top: 0.2rem;
      font-size: 20px;
    }
  }

  .list-item-card-title {
    color: $mirage;
    font-weight: 500;

    outline: none;
    div {
      outline: none;
    }
  }

  &:first-of-type {
    border-top: 0px;
  }

  &:hover {
    background-color: $catskill-white;
    cursor: pointer;
    color: $mirage;
  }
}

.info-card {
  padding: 1.5rem;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }

  .info-icon {
    @media (min-width: 1188px) {
      max-width: 65px;
    }
  }

  .icon {
    width: 35px;
    height: 35px;
    position: relative;
    top: 5px;
  }

  .id {
    font-size: 12px;
    letter-spacing: -0.25px;
  }

  p {
    margin-bottom: 0rem;
  }
}
