.mce-tinymce {
  background-color: white !important;
  border: 1px solid $mystic !important;
  border-radius: 4px !important;
  box-shadow: none !important;
}

.mce-toolbar-grp {
  background-color: $catskill-white !important;
  border-top-left-radius: 4px !important;
  border-top-right-radius: 4px !important;
}

.mce-container-body {
  color: $mirage !important;
}

.content-editor-error > .float-label > .mce-container > .mce-container-body {
  border: 2px solid #dd4d5f !important;
  border-radius: 0.25em;
}

.mce-top-part::before {
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
}

.mce-flow-layout {
  padding: 5px 0px 0px 5px !important;
}

.mce-btn-group {
  padding: 0px 0px 5px 0px !important;
  border-left: 0px !important;
  margin-left: 0px !important;
}

.mce-btn {
  margin-left: 0px !important;
  margin-right: 5px !important;
  border: 1px solid $mystic !important;
  border-radius: 3px !important;

  button {
    padding: 5px 7px !important;
    color: $mirage !important;
  }
}

.mce-btn.mce-active,
.mce-menu-item:hover,
.mce-menu-item:focus {
  background: $catskill-white !important;
}

// Text color picker on hover //

.mce-splitbtn .mce-open {
  border-left: 0px !important;
}

// Text color picker icon styling //

.mce-i-backcolor {
  background: $catskill !important;
}

.mce-colorbutton .mce-preview {
  margin-left: -8px !important;
  background-color: $nepal !important;
}

// Dropdown top arrow //

.mce-floatpanel.mce-popover.mce-bottom > .mce-arrow {
  border-bottom-color: $mystic !important;
}

// Color picker //

.mce-grid {
  border-spacing: 0px !important;
  margin: 5px !important;
}

.mce-grid td.mce-grid-cell div {
  border: 0px !important;
  width: 19px !important;
  height: 19px !important;
}

.mce-colorbtn-trans div {
  color: $lynx !important;
}

// Dropdown menus //

.mce-floatpanel {
  border-radius: 4px !important;
  border: 1px solid $catskill !important;
  box-shadow: 5px 5px 20px rgba(30, 43, 58, 0.05) !important;
}

.mce-menu-item {
  margin-bottom: 0px !important;
  padding: 8px !important;
  border-left: 0px !important;
}

.mce-text {
  color: $mirage !important;
}

.mce-ico {
  color: $mirage !important;
}

.mce-caret {
  border-top: 4px solid $lynx !important;
}

.mce-menu-item.mce-active.mce-menu-item-normal {
  background: $catskill-white !important;
}

.mce-menubtn.mce-fixed-width span {
  width: 38px !important;
  margin-right: 15px !important;
}

.mce-splitbtn .mce-open {
  width: 30px !important;
  position: relative !important;
  left: -30px !important;
  margin-right: -30px !important;

  .mce-caret {
    border-top: transparent !important;
  }
}

.mce-btn-has-text .mce-open {
  .mce-caret {
    border-top: $lynx !important;
  }
}

.mce-edit-area {
  border: none !important;
  border-top: 1px solid $mystic !important;
  padding-bottom: 3px !important;
  background: transparent !important;
}

.mce-window {
  .mce-textbox {
    border: 1px solid $mystic !important;
    border-radius: 4px !important;
    color: $mirage !important;
  }
}

.mce-window-head {
  border-bottom: none !important;
  padding: 15px 15px 0px !important;
}

.mce-title {
  color: $mirage !important;
  font-size: 16px !important;
}

.mce-close {
  padding: 10px;

  i {
    color: $lynx !important;
  }
}

.mce-foot {
  border-top: none !important;
}

.mce-btn.mce-abs-layout-item {
  border-radius: 100px !important;
  margin-right: 10px !important;
  color: $lynx !important;
  border: 1px solid $mystic !important;
  margin-left: 0px !important;

  span {
    color: $lynx !important;
    font-weight: 500 !important;
  }
}

.mce-btn.mce-primary {
  background: $winred-blue !important;
  color: white !important;
  border: 1px solid $winred-blue !important;
  margin-right: 10px;

  span {
    color: $white !important;
    font-weight: 500 !important;
  }
}

// URL pop up last select //

#mceu_215 {
  border-radius: 4px !important;
  margin-left: 0px !important;

  span {
    color: $mirage !important;
  }
}

.mce-abs-layout-item .mce-first {
  color: $mirage !important;
}

// Image pop up checkbox //

.mce-i-checkbox {
  border: 1px solid $mystic !important;
  border-radius: 3px;
}

.mce-label {
  color: $mirage !important;
}

// Hides Code Path item and space from status bar - Code Path is disabled in content editor

.mce-path-item {
  display: none !important;
}
