@import '../../styles/design_system/colors.scss';

.custom-close-btn {
  position: absolute;
  right: 10px;
  top: 25%;
}

.collapsible-card {
  .card-title {
    width: 100%;
    .card-statistics {
      text-align: right;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  &.collapsible-card-errors {
    border: 1px solid $winred-red-light !important;

    .collapsible-card-header {
      border-bottom: 1px solid $winred-red-light !important;
      background-color: $rose;
    }
  }

  &.collapsible-card-errors-closed {
    border-bottom: none !important;
  }
  .collapse-flex-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .collapsible-card-header {
    padding: 1rem 1.5rem;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;

    &.right-side-component {
      color: $lynx;
      font-size: 0.75rem;
      font-weight: 400;
      text-transform: uppercase;
      letter-spacing: 0.15rem;
    }

    &.custom-header {
      text-transform: none;
    }

    &:not(.custom-header) {
      color: $lynx;
      font-size: 0.75rem;
      font-weight: 400;
      text-transform: uppercase;
      letter-spacing: 0.15rem;
    }

    .collapsible-header-content {
      padding-right: 0;
      margin-right: 0;
      flex-grow: 2;
    }

    @media (max-width: 576px) {
      padding: 1rem;
    }

    .collapsible-btns {
      padding-left: 1rem;
      flex-grow: 0.2;

      .center-buttons {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
      }

      .btn {
        &.winred-btn--alt {
          border: 1px solid $nepal !important;
        }

        &.btn-error:active {
          background-color: $rose !important;
          color: $winred-red !important;
        }

        &:active,
        &:focus {
          box-shadow: none;
        }
        white-space: nowrap;
      }
    }

    @media (max-width: 767px) {
      .collapsible-btns {
        flex-wrap: wrap;
        align-items: flex-start;
        max-width: 112px;
      }
      &.collapsible-drag {
        display: flex;
        flex-direction: column;

        .btn,
        .custom-remove-icon {
          float: none !important;
        }

        .collapsible-btns {
          margin-left: 25px;
          margin-top: 10px;

          .btn,
          .custom-remove-icon {
            &:active,
            &:focus {
              box-shadow: none;
            }

            margin-left: 15px;
          }
        }

        .collapse-drag-icon {
          align-items: start !important;
        }
      }
    }

    /* Put a border below the header if the content body is open. */
    &.open {
      border-bottom: 1px solid $mystic;
    }

    .btn,
    .custom-remove-icon {
      border-radius: 25px;
      padding: 0.5rem 1rem 0.5rem 1rem;
    }

    .btn.collapse-remove-btn {
      background-color: $rose;
      color: $winred-red;
      margin-left: 15px;

      &:hover {
        background-color: $rose !important;
        color: $winred-red !important;
      }
    }

    /* Don't show an outline around the expand/collapse button. */
    [role='button']:focus {
      outline: 0;
    }
  }

  .collapse-body {
    display: none;
  }
}
