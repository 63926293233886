@import 'variables';

.table {
  margin-bottom: 0;

  thead {
    background-color: $table-head-bg;

    th {
      border: 0px;
      background-color: transparent;
      padding: $table-head-cell-padding;
      text-transform: uppercase;
      white-space: nowrap;
      font-weight: 500;
    }

    th.wrap {
      white-space: normal;
      word-break: normal;
    }
  }

  // Style first column of table
  tr {
    border-bottom: 1px solid $catskill;

    .icon-cell {
      font-size: 20px;
      width: 35px;
      height: 67px;

      span {
        width: 20px;
        height: 20px;
      }

      svg {
        width: 20px;
        height: 20px;
      }
    }

    .image-cell {
      width: 50px;
    }
    .button-cell {
      padding-right: 0;
      margin-right: 0;
    }
  }

  td {
    padding: 1.45rem 0rem 1.45rem 1.1rem;
    border: 0px;
    vertical-align: middle;
  }

  td.download-icon {
    font-size: 20px;
    padding: 17px 16px 15px;
  }


  .payout_row {
    box-shadow: inset 0 -2px 0 0 $winred-blue-light;
    border: 0px;
  }

  .upcoming-payout {
    svg {
      color: $winred-blue-light;
    }
  }
}

.payout-details-table {
  table th {
    padding: 0rem 1rem 0.75rem;
  }

  td:hover {
    cursor: inherit;
  }

  .table-hover tbody tr:hover {
    background-color: inherit;
    color: inherit;
    cursor: inherit;
  }

  .table-hover {
    cursor: inherit;
  }
}

.winred-table {
  color: $lynx;
  font-size: 14px;

  td {
    max-width: 300px;
    word-break: break-word;
  }
}
