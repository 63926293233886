@import 'styles/design_system/colors';

.hide-sub-menu {
  position: absolute;
}

/** if screen si too small then dropdown needs to be centered
    to page not the parent element (open icon)*/
@media (max-width: 410px) {
  nav {
    .dropdown {
      position: inherit;
    }
  }
}

.navbar-avatar-image {
  object-fit: cover;
  border-radius: 50%;
  height: 30px;
  width: 30px;
}

/** wrap for main content and navbar and pull out candidate side bar */
.site-wrap {
  transition: width 0.3s ease 0s;
  width: 100%;

  &.open {
    width: calc(100% - 300px);
  }
}

.menu-box {
  border: 1.5px solid $mystic;
  border-radius: 5px;
  font-size: 16px;
  min-width: 100px;
  user-select: none;

  .menu-avatar-container {
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    .navbar-avatar-image {
      height: 30px;
      width: 30px;
    }
  }
}

.candidate-menu-container {
  position: fixed;
  right: -200%;
  top: 0;
  bottom: 0;
  background-color: white;
  box-shadow: 0px 10px 28px -7px $catskill;
  transition: right 0.2s ease 0s;
  z-index: 600;

  &.open {
    position: fixed;
    width: 345px;
    right: 0;
    overflow-y: scroll;
  }

  &.submenu-open {
    top: 75px;
  }
}

@media (max-width: 1200px) {
  .site-wrap {
    &.open {
      width: 70%;
    }
  }

  .candidate-menu-container {
    &.open {
      width: 30%;
    }
  }
}

@media (max-width: 992px) {
  .site-wrap {
    &.open {
      width: 100%;
    }
  }

  .candidate-menu-container {
    &.open {
      min-width: 300px;
      width: 100%;
    }
  }

  .menu-box {
    min-width: 0px;
  }
}

/** end wrap for main content and navbar and pull out candidate side bar */

.flex-grow-desktop-nav {
  flex-grow: 4;

  @media (max-width: 992px) {
    flex-grow: 0;
  }
}
