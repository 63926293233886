@import '../../../../styles/design_system/colors.scss';

.winred-input2 {
  position: relative;

  .form-control {
    font-family: Roboto, sans-serif;
    font-size: 14px !important;
    font-weight: 500;
    line-height: 1.75;
    border: 1px solid $mystic;
    background-color: $white;
    color: $winred-blue;

    &:disabled {
      color: $lynx;
      background-color: $catskill-white;

      &::placeholder {
        color: $lynx;
      }
    }
  }

  .float-label {
    position: relative;
    flex-grow: 1;
    &.no-wrap {
      display: flex;
      flex-wrap: nowrap;
    }

    &.float-label-search {
      label {
        left: 50px;
        top: 12px;

        &.label-float {
          left: 50px;
          top: 4px;
        }
      }
    }
  }

  .label {
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: 500;
    position: absolute;
    pointer-events: none;
    left: 16px;
    top: 14px;
    transition: 0.1s ease all;
    z-index: 5;

    &.winred-input-float-error {
      color: $winred-red-light !important;
    }

    &.label-float {
      color: $lynx;
      font-size: 10px;
      line-height: 11px;
      top: 4px;
      left: 12px;
    }
  }

  .winred-input-error {
    border: 1.4px solid $winred-red-light !important;
    border-radius: 0.25em;
    background-color: $rose !important;
    font-size: 14px;

    &:focus {
      background-color: $rose;
    }
  }
}

.remove-border {
  border: none !important;
}

.prepend-label-dollar {
  .winred-input2 .label {
    left: 15px;
    top: 14px;

    &.label-float {
      top: 4px;
      left: 15px;
    }
  }
}

.winred-input2-select {
  border: none;

  input {
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
  }

  .float-label .placeholder {
    z-index: 2;
  }

  .winred-input--error_select > div {
    border: 2px solid $winred-red-light !important;
    border-radius: 0.25em;
    background-color: $rose;

    &::placeholder {
      color: $winred-red-light !important;
    }
  }
}

.date-range-input {
  flex-wrap: initial !important;

  .start-date {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    font-weight: 500;
    font-family: Roboto, sans-serif;
  }

  .end-date {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    font-weight: 500;
    font-family: Roboto, sans-serif;
  }
}

.input-group-prepend .input-group-text,
.input-group-append .input-group-text {
  background-color: $catskill-white;
  padding-left: 12px;
  padding-right: 12px;
  border-color: $mystic;
  color: $lynx;
}

.input-group-append .input-group-text {
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
}

.form-control {
  &:focus {
    color: $winred-blue;
    border-color: $nepal;
    box-shadow: none;
  }
}

.form-control[disabled] {
  color: $mystic;
  border: 1px solid $mystic;
}

.input-group > .form-control:not(:first-child) {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.winred-text-editor-error > .mce-container > .mce-container-body {
  border: 2px solid $winred-red-light !important;
  border-radius: 0.25em;
}

.amount-predicate {
  button {
    padding: 1rem;
  }
}

.search-input-container {
  position: relative;

  svg {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;

    pointer-events: none;
  }

  .winred-input2 {
    .label {
      left: 40px;
    }
    input {
      padding-left: 40px;
    }
  }

  @media (max-width: 991px) {
    &:not(.expand) {
      .winred-input2 {
        .label {
          left: 40px;
        }
        input {
          padding-left: 0px;
        }
      }
      width: 50px;
      .winred-input2 {
        label {
          display: none;
        }
      }

      [data-icon] {
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
      }
    }
  }
}

.react-datepicker-wrapper {
  width: 100%;
}

.collapsed-select-option {
  display: none;
}

.group-heading-wrapper {
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: large;
}

.black-text {
  color: black;
}

.winred-credit-card {
  input {
    border: none;
  }
  .winred-input-error {
    border: 2px solid $winred-red-light !important;
    border-radius: 0.25em !important;
    input {
      background-color: $rose !important;
      font-size: 14px;

      &:focus {
        background-color: $rose;
      }
    }
  }
  .input-group {
    border-width: 1px;
    border-style: solid;
    border-color: $mystic;

    border-radius: 0.25em;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;

    .form-control {
      color: $lynx !important;
      font-weight: 600;
    }
  }
  .input-group-text {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-number {
    width: 67% !important;
  }
  .expiry-date {
    width: 13% !important;
  }

  .zip,
  .cvc {
    width: 10% !important;
  }
}

.stripe-error {
  border: 2px solid $winred-red-light !important;
  background-color: $rose !important;
}

.react-stripe-card-element {
  border-width: 1px;
  border-style: solid;
  border-color: $mystic;
  border-radius: 0.25em;
  padding: 1rem;
  font-size: 15px;
  color: #1e2b3a;
  font-weight: 500;
}

.moov-credit-card {
  ._iframe_18nwq_1 {
    width: 100% !important;
    background: none !important;
    border-width: 1px !important;
    border-style: solid !important;
    border-color: $mystic !important;
    border-radius: 0.25em !important;
    padding: 1rem !important;
    height: 50px !important;
    input::-webkit-autocomplete {
      display: none;
    }
  }
}

.moov-card-error {
  ._iframe_18nwq_1 {
    border: 2px solid $winred-red-light !important;
    background-color: $rose !important;
  }
}
