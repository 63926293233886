@import './design_system/colors.scss';

.search-filter {
  .btn-toolbar {
    justify-content: flex-end;

    button {
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.react-datepicker-popper {
  z-index: 201;
}

.react-datepicker__triangle {
  display: none;
}

.popover {
  border-radius: 4px;
  border: 1px solid $catskill;
  box-shadow: 5px 5px 20px rgba(30, 43, 58, 0.05);
}

.arrow {
  &::after,
  &::before {
    border-width: 1px 1px 1px !important;
    border-bottom-color: $catskill !important;
  }
}

.popover-body {
  padding: 1rem;

  .form-control {
    border-color: $mystic;
    font-weight: 500;
    font-family: Roboto, sans-serif;

    &:focus,
    &:active {
      border-color: $nepal;
    }
  }

  .input-group-text {
    color: $lynx;
    background-color: $catskill-white;
    font-weight: 500;
    border-color: $mystic;
  }

  label {
    font-weight: 500;
    font-size: 14px;
    color: $mirage;
  }

  .btn {
    padding: 10px 15px;
  }

  .dropdown-menu {
    border-color: $catskill;

    .dropdown-item:hover {
      background-color: $catskill-white;
    }
  }
}

.filter-popover {
  border-radius: 4px;
  border: 1px solid #e9eef6;
  box-shadow: 5px 5px 20px rgba(30, 43, 58, 0.05);
  width: 60%;
  top: 4.2em;
  right: 0.9em;
  z-index: 450;
  background-color: white;
  padding: 1rem;
  width: 100%;
  max-width: 300px;

  .form-control {
    border-color: $mystic;
    font-weight: 500;
    font-family: Roboto, sans-serif;

    &:focus,
    &:active {
      border-color: $nepal;
    }
  }

  .form-group {
    text-align: left;
  }

  .input-group-text {
    color: $lynx;
    background-color: $catskill-white;
    font-weight: 500;
    border-color: $mystic;
  }

  label {
    font-weight: 500;
    font-size: 14px;
    color: $mirage;
  }

  .btn {
    padding: 10px 15px;
  }

  .btn-toolbar {
    justify-content: flex-end;
    button {
      margin-left: 0.6em;
    }
  }
}
