/* Color Variables */
// http://chir.ag/projects/name-that-color/#3D59FF

$winred-blue: #003060;
$winred-blue-light: #345980;
$winred-blue-hover: #426488;

$winred-red: #d93a4d;
$winred-red-light: #dd4d5f;
$winred-red-dark: #a13d49;

$winred-green: #61a578;
$winred-green-hover: #78b38c;
$algae-green: #9ee6c3;
$sea-green: #307963;
$jagged-ice: #c4eadc;
$panache: #fafdfb;
$surf-crest: #cbe5d0;

$white-ice: #f7fefa;
$ice-white: #effdf5;

$mirage: #1e2b3a;
$pickled-bluewood: #243344;
$pickled-bluewood-light: #314459;
$blue-charcoal: #000a14;

$dark-purple: #543ba2;
$light-purple: #edebf6;

$athens-gray: #eff1f4;
$nepal: #94aac1;
$mystic: #dbe1ec;
$catskill: #e9eef6;
$catskill-white: #f5f8fa;
$white: #ffffff;
$lynx: #627890;

$moon-raker: #cedbf7;
$zicron: #f8faff;
$dodger-blue: #3d59ff;

$geraldine: #ff797f;
$rajah: #fabf68;
$banana: #fff3ed;
$coral: #ff8b4d;
$whiskey: #d1a664;

$rose-white: #fff8f8;
$rose: #fff8f9;
$oyster-pink: #e7c6c8;
$romance: #fffcf9;
$pearl-bush: #e6dbcc;
$porcelain: #fcfdfd;

// Brand colors
$facebook-blue: #4e71a8;
$twitter-blue: #1da1f2;

$colors: (
  'algae-green': $algae-green,
  'sea-green': $sea-green,
  'mirage': $mirage,
  'pickled-bluewood': $pickled-bluewood,
  'pickled-bluewood-light': $pickled-bluewood-light,
  'lynx': $lynx,
  'nepal': $nepal,
  'mystic': $mystic,
  'catskill-white': $catskill-white,
  'catskill': $catskill,
  'white': $white,
  'geraldine': $geraldine,
  'rajah': $rajah,
  'banana': $banana,
  'coral': $coral,
  'whiskey': $whiskey,
  'panache': $panache,
  'surf-crest': $surf-crest,
  'rose-white': $rose-white,
  'oyster-pink': $oyster-pink,
  'romance': $romance,
  'pearl-bush': $pearl-bush,
  'winred-blue': $winred-blue,
  'winred-blue-light': $winred-blue-light,
  'winred-blue-hover': $winred-blue-hover,
  'winred-red': $winred-red,
  'winred-red-dark': $winred-red-dark,
  'winred-green': $winred-green,
  'twitter-blue': $twitter-blue,
  'porcelain': $porcelain,
  transparent: 'transparent',
  'dark-purple': $dark-purple,
  'light-purple': $light-purple,
  'jagged-ice': $jagged-ice,
  'black': black,
  'moon-raker': $moon-raker,
  'zicron': $zicron,
  'dodger-blue': $dodger-blue,
);

:export {
  algae-green: $algae-green;
  jagged-ice: $jagged-ice;
  sea-green: $sea-green;
  mirage: $mirage;
  pickled-bluewood: $pickled-bluewood;
  pickled-bluewood-light: $pickled-bluewood-light;
  lynx: $lynx;
  nepal: $nepal;
  mystic: $mystic;
  catskill-white: $catskill-white;
  white: $white;
  geraldine: $geraldine;
  rajah: $rajah;
  banana: $banana;
  coral: $coral;
  whiskey: $whiskey;
  rose-white: $rose-white;
  oyster-pink: $oyster-pink;
  romance: $romance;
  pearl-bush: $pearl-bush;
  winred-blue: $winred-blue;
  winred-blue-light: $winred-blue-light;
  winred-blue-hover: $winred-blue-hover;
  winred-red: $winred-red;
  winred-red-dark: $winred-red-dark;
  winred-green: $winred-green;
  twitter-blue: $twitter-blue;
  porcelain: $porcelain;
  dark-purple: $dark-purple;
  light-purple: $light-purple;
  black: black;
  moon-raker: $moon-raker;
  zicron: $zicron;
  dodger-blue: $dodger-blue;
}

// Creates utility classes for each color
// .c--{color-name}
// .bg--{color-name}
// .border--{color-name}
@each $name, $value in $colors {
  .c--#{'' + $name} {
    color: $value;
  }

  .bg--#{'' + $name} {
    background-color: $value;
    &:active {
      background-color: $value !important;
    }
    &:focus {
      background-color: $value !important;
    }
  }

  .border-#{'' + $name} {
    border-color: $value !important;
  }

  .hover-border--#{'' + $name}:hover {
    border-color: $value !important;
  }
  .hover-bg--#{'' + $name}:hover {
    background-color: $value;
  }
  .hover-c--#{'' + $name}:hover {
    color: $value;
  }
}
