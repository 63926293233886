@import '../../styles/design_system/colors.scss';

.sidebar-info-text {
  font-size: 1.5rem;
}

.circle-picker-container,
.picker-container {
  position: absolute;
  z-index: 100;
  top: -125px;
  background-color: white;
  border-radius: 4px;
  box-shadow: 5px 5px 20px rgba(30, 43, 58, 0.05);
  border: 1px solid $catskill;
  padding: 1rem !important;
}

.picker-container {
  input {
    width: 100% !important;
    color: $mirage !important;
    font-size: 12px !important;
    font-weight: 500 !important;
    font-family: Roboto, sans-serif !important;
    padding: 5px !important;
    border-radius: 2px;
    border: 1px solid $mystic !important;
    box-shadow: none !important;
    text-align: center !important;
  }

  span {
    color: $lynx !important;
    font-weight: 500 !important;
    font-family: Roboto, sans-serif !important;
    width: 100%;
  }

  .winred-sketch-picker {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    box-shadow: none !important;
    padding: 1rem;
  }

  .btn {
    margin: 0.5rem 0rem 0rem 0rem;
    width: 100%;
    display: block;
  }
}

.sketch-picker {
  padding: 0px !important;
}

.page-image-square {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 4px;
  border: $mystic 1px solid;
}

.page-image-rectangle {
  width: 80px;
  height: 50px;
  object-fit: cover;
  border-radius: 4px;
  border: $mystic 1px solid;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
}

.background-image-preview {
  max-height: 100%;
  max-width: 100%;
  align-content: center;
}

.color-picker-row {
  display: flex;
  align-items: flex-start;

  .color-picker-circle-wrapper {
    position: relative;
    margin-right: 1rem;
    margin-top: 6px;

    .color-picker-circle {
      width: 36px;
      height: 36px;
      border-radius: 100px;
      border: 1px solid $mystic;
      padding: 5px;
      background-clip: content-box;
      background-color: $catskill-white;

      &:focus,
      &:active {
        outline: none;
      }
    }

    .color-picker-interface-wrapper {
      position: absolute;
      z-index: 10;
      bottom: 2rem;
      left: 2rem;
    }
  }
}

.winred-input-color {
  color: $lynx !important;
  cursor: pointer;
}

.winred-image-gallery-item {
  text-align: center;
  margin-bottom: 1rem;
  cursor: pointer;
  height: 6rem;
  display: flex;
  flex-direction: column;
  background-color: $mirage;
  border-radius: 4px;
  overflow: hidden;
  background: $catskill-white;
  border: 1px solid $catskill;

  &.selected {
    box-shadow: 0px 0px 0px 4px $winred-blue;
  }

  img {
    object-fit: cover;
  }
}

.error-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #edeff2;
  height: 100vh;
  padding: 3rem 1rem;

  .page-title {
    height: 48px;
    color: $mirage;
    font-family: Roboto;
    font-size: 36px;
    font-weight: 500;
    letter-spacing: -0.75px;
    line-height: 48px;
  }
  .body {
    text-align: center;
  }
  .error-code {
    height: auto;
    width: auto;
    color: #edeff2;
    font-size: 350px;
    font-weight: 500;
    line-height: 1.2;
    text-shadow: 0 40px 200px rgba(105, 128, 153, 0.33);

    @media (max-width: 991px) {
      font-size: 200px;
    }

    @media (max-width: 575px) {
      font-size: 150px;
    }
  }

  .winred-btn {
    margin-top: 1rem;
  }
}

.team-page-name {
  min-width: 14rem;
}
.view-team-page {
  padding: 0.25rem 0.5rem;
  border: 1px solid $catskill;
  border-radius: 4px;
  background-color: white;
  color: $mirage;
  margin-top: -5px;
  margin-bottom: -5px;
  font-weight: 500;
  font-size: 14px;

  &:hover {
    background-color: $catskill-white;
  }
}

button.share-team-page {
  padding: 0.25rem 0.5rem;
  border-radius: 0.2rem;
  border: 1px solid $winred-blue-hover;
  background-color: $winred-blue-hover;
  margin-top: -5px;
  margin-bottom: -5px;
  font-weight: 500;
  font-size: 14px;
  color: $white;
  margin-right: 1rem;

  &:hover {
    border: 1px solid $winred-blue;
    background-color: $winred-blue;
  }
}

button.request-connected-page-removal {
  padding: 0.25rem 0.5rem;
  border-radius: 0.2rem;
  background-color: white;
  border: 1px solid $catskill;
  color: $mirage;
  font-weight: 500;
  margin-top: -5px;
  margin-bottom: -5px;

  &:hover {
    background-color: $catskill-white;
  }
}

.share-button {
  color: $white;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  padding: 0.8rem;
  font-weight: bold;
  border-radius: 0.35rem;
  max-width: 400px;
  border: 0px;
  margin-left: 1rem;
  margin-right: 1rem;

  & + .share-button {
    margin-left: 0rem;
  }

  &.twitter {
    background-color: $twitter-blue;
  }

  &.facebook {
    background-color: $facebook-blue;
  }

  &:hover {
    text-decoration: none;
    color: $white;
  }
}

.url-copy-text {
  color: $winred-blue-hover;
  font-weight: 500;
}

.og-preview-image {
  background-color: $catskill-white;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  overflow: hidden;

  img {
    width: 100%;
    border-bottom: 1px solid $catskill;
  }
}

.og-preview-description {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  display: block;
}

.money-pledge-preview,
.donor-covers-fees-preview {
  display: flex !important;
  flex-direction: row !important;
  border: 1px solid $winred-red !important;
  padding: 1rem 0.5rem !important;
  margin: 1rem 0rem;

  .custom-checkbox {
    margin-left: 1rem;
    margin-right: 1rem;
    margin-top: 0.1rem;
  }
  .custom-control-label:hover {
    cursor: pointer;
  }

  .money-pledge-text {
    .title {
      color: $winred-red;
      font-weight: 500;
      margin-bottom: 0.25rem;
      text-align: center;
    }
  }

  .custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
    background-color: $winred-red;
    box-shadow: 0 0 3px $winred-red;
    border-color: $winred-red;
  }
  .custom-checkbox .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 3px $winred-red;
  }
  .custom-checkbox .custom-control-input:active ~ .custom-control-label::before {
    box-shadow: 0 0 3px $winred-red;
    background: $winred-red;
  }
  .custom-checkbox .custom-control-label::before {
    border-radius: 0.25rem;
  }
  .custom-control-label::before {
    background-color: #fff;
  }
}

.custom-cards-container {
  .remove-button {
    color: $lynx;
    font-size: 0.75rem;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 0.15rem;
  }

  label {
    font-size: 17.5px;
    line-height: 1.2;
    font-weight: 500;
  }

  .preview-card {
    @media (max-width: 768px) {
      margin-top: 1rem;
    }

    .card-title {
      margin-bottom: 0px;
    }

    .preview-title {
      font-size: 14px;
      margin-bottom: 0.5rem !important;
    }

    .form-group {
      margin-bottom: 0px;
    }

    .form-check {
      margin-bottom: 5px;

      &:last-of-type {
        margin-bottom: 0px;
      }
    }

    .form-check-input {
      margin-top: 0.2rem;
    }

    .form-check-label {
      font-size: 14px;
      font-weight: 400;
      color: $lynx;
    }
  }
}

.custom-fields-container {
  .float-label p {
    font-size: 17.5px;
    line-height: 1.2;
    font-weight: 500;
    margin-bottom: 0.5rem;

    &.p--red {
      color: $winred-red-light;
    }

    &.p--medium {
      font-size: 14px;
      font-weight: 500;
    }
  }
}

.donation-amounts-actions-toggle {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: auto;
  padding-bottom: 0.25rem;

  .clear-amounts-btn {
    padding: 0.5rem;
    margin-top: -0.5rem;
    margin-bottom: -0.5rem;
    margin-right: 1rem;
  }
}

.donor-covers-fees-preview {
  width: 310px;

  .custom-checkbox {
    margin-left: 0;
    margin-right: 0;
  }
}

.donation-amounts-header {
  display: flex;
  flex-direction: row;
  align-items: center;
}

@media (max-width: 576px) {
  .donation-amounts-actions-toggle {
    flex-direction: column-reverse;
    .cog-button-col {
      margin-bottom: 1rem;
    }
    .clear-amounts-btn {
      padding: 0.5rem;
      margin-top: 0rem;
      margin-bottom: 0rem;
      margin-right: 0rem;
    }
    .pointer {
      margin-left: auto;
    }
  }
  .donation-amounts-header {
    align-items: flex-start !important;
  }
}

.page-billpay-notify.dropdown-item {
  color: $white !important;
  &.disabled,
  &:hover {
    pointer-events: inherit;
    background-color: $dark-purple !important;
  }
}

.page-high-dollar-notify.dropdown-item {
  color: $white !important;
  &.disabled,
  &:hover {
    pointer-events: inherit;
    background-color: $whiskey !important;
  }
}

.page-list-expanded {
  > div:not(:first-child) {
    padding-top: 1rem;
    border-top: 1px solid $mystic;
  }
}

.campaign-product-image {
  height: 45px;
  width: 45px;
}

.other-field-input-preview {
  display: flex;
  align-items: center;
  gap: 5px;
}

.page-builder-tag-list {
  min-width: 285px;
}
