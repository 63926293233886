.no-outline {
  outline: none;

  &:focus,
  &:active {
    outline: none;
  }
}

.border-dashed {
  border-style: dashed !important;
}
