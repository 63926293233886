@import '../../../../styles/design_system/colors.scss';

.page-tag-menu-overlay {
  z-index: 100;
  width: 320px;
}

.desktop-position {
  top: 2px;
  left: 0px;
}
.mobile-position {
  top: -120px;
  left: 25px;
}

.page-tag-menu-static {
  top: -63px;
  left: 0px;
  width: 100%;
  z-index: 10000;
}

.page-tag-menu-selector {
  position: absolute;
  box-shadow: 5px 5px 20px rgba(30, 43, 58, 0.05);
  background-color: white;
  border-radius: 4px;
  border: 1px solid $catskill;

  .search-bar {
    padding: 0.25rem 0.5rem;
  }

  .list-body,
  .search-bar,
  .tag-options {
    border-bottom: 1px $catskill solid;
  }

  .footer {
    padding: 1rem;
  }

  .list-body {
    overflow-y: scroll;
    padding: 0.5rem 1rem;
  }
}

.main-tag-drawer,
.sub-tag-drawer,
.manage-tags-drawer {
  background-color: $catskill-white;
}

.main-tag-drawer {
  .sub-tag-header {
    @media (max-width: 576px) {
      padding-left: 12px;
      padding-right: 12px;
    }
  }
}

.manage-tabs-header,
.main-tag-table-header {
  background-color: $catskill-white !important;
}

.override-disabled {
  background: $white !important;
}
