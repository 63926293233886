.word-wrap-break-word {
  word-wrap: break-word !important;
}

.word-wrap-break-all {
  word-break: break-all !important;
}

$fontSizes: (
  'tiny': 10px,
  'smaller': 0.75rem,
  'small': 14px,
  'normal': 1rem,
  'large': 1.25rem,
);

@each $name, $size in $fontSizes {
  .font-#{'' + $name} {
    font-size: $size;
  }
}
@each $name, $size in $spacers {
  .leading-#{'' + $name} {
    line-height: $size;
  }
}

.leading-none {
  line-height: 1;
}

.tracking-widest {
  letter-spacing: 2px;
}
