$custom-sizes: (
  '6': 4rem,
  '18': 12rem,
  '19': 13rem,
  '21': 15rem,
);

// Use both the bootstrap builtin sizes and our custom sizes.
@each $name, $size in map-merge($spacers, $custom-sizes) {
  .height-#{'' + $name} {
    height: $size;
  }
  .min-height-#{'' + $name} {
    min-height: $size;
  }
  .width-#{'' + $name} {
    width: $size;
  }
  .max-width-#{'' + $name} {
    max-width: $size;
  }
  .min-width-#{'' + $name} {
    min-width: $size;
  }
}

.height-screen {
  height: 100vh;
}
