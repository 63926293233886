.winred-sub-nav {
  color: $pickled-bluewood-light;
  background-color: $catskill-white;
  font-size: 14px;
  min-height: 40px;
  box-shadow: inset 0 -2px 0 0 $catskill;
}

.winred-nav__nav-item {
  padding: 17px 10px;
  white-space: nowrap;

  @media (max-width: 1220px) {
    padding: 17px 5px;
  }

  @media (max-width: 1040px) {
    padding: 17px 1px;
  }

  &:focus {
    border: none;
    outline: none;
  }

  &:hover {
    box-shadow: inset 0 -2px 0 0 $nepal;
  }

  &:first-child {
    margin-left: 0;
  }
}

.winred-nav__nav-link {
  color: $pickled-bluewood-light !important;
  padding: 10px;

  &:focus {
    border: none;
    outline: none;
  }

  &:hover {
    color: $pickled-bluewood-light !important;
  }
}

.winred-nav-logo {
  padding-left: 0rem;
  padding-right: 0.5rem;

  img {
    height: 24px;
  }

  @media (max-width: 991px) {
    padding-top: 0px;

    img {
      height: 30px;
    }
  }
}

/* parent class needed to override bootstrap tab > link hover specificity */
.nav-tabs .winred-nav__nav-link:hover {
  border: 1px solid transparent;
}

.winred-nav__dropdown-item {
  padding: 15px 10px;
}

.dropdown-item-bordered {
  border-bottom: 1px solid $catskill !important;
}

/** Nav Items **/
.winred-nav__nav-item--active {
  box-shadow: inset 0 -2px 0 0 $winred-red;
  font-weight: 500;
  letter-spacing: -0.065px;

  &:hover {
    box-shadow: inset 0 -2px 0 0 $winred-red;
  }

  .winred-nav__nav-link {
    color: $winred-red !important;

    svg {
      color: $winred-red !important;
    }
  }
}

.winred-nav__nav-item--hover {
  box-shadow: inset 0 -2px 0 0 $nepal;

  .title-container {
    color: rgba(0, 0, 0, 0.7);
  }
}

.navdropdown-title {
  color: rgba(0, 0, 0, 0.5);
}

.winred-nav__menu-nav-item {
  height: 100%;
  padding: 12px 10px 18px 10px;
  margin-left: 10px;
  white-space: nowrap;

  &:focus {
    border: none;
    outline: none;
  }

  .dropdown-toggle.nav-link {
    padding: 0px;
    height: 45px;
  }

  .dropdown-menu {
    border: 1px solid $catskill;
    border-radius: 4px;
    background-color: white;
    padding: 0rem;
    box-shadow: 5px 5px 20px rgba(30, 43, 58, 0.1);
    min-width: 220px;

    a {
      white-space: break-spaces;
    }
  }

  .dropdown-item {
    padding: 1rem;

    &.inline-search-container {
      pointer-events: initial;
      padding: 0px;

      input {
        border: 0px;

        &:focus {
          box-shadow: none !important;
        }
      }
    }
  }

  .dropdown-item,
  .dropdown-item.active {
    color: $mirage;
  }
  .dropdown-item.disabled:not(.candidate-info):not(.user-info) {
    color: $lynx;
  }

  .icon {
    margin-right: 0.5rem;
    font-size: 1rem;
  }

  .dropdown-item:not(.user-info) {
    &:hover {
      background-color: $catskill-white;
    }
  }

  .candidate-info,
  .user-info {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    border-bottom: 1px solid $catskill;
    background: transparent;
    padding: 1rem 1rem;

    .user-info-container {
      flex: 5;
      display: flex;
      flex-direction: column;
      margin-left: 1rem;
    }

    p {
      margin-bottom: 0px;
    }
  }
}

.winred-nav--caret {
  color: $pickled-bluewood-light;
  margin-left: 0.5rem;
}

.winred-nav__menu-nav-item.mobile {
  display: none;

  &:first-of-type {
    margin-left: auto;
  }
}

.top-nav-bar {
  padding-left: 0;
  padding-right: 0;
  background-color: $white;
  position: sticky;
  top: 0;
  z-index: 500;

  &.top-nav-bar-winred-user {
    // hide extra bootstrap toggle menu on candidate menu - its unused
    .dropdown-menu.show {
      display: none;
    }
  }

  //   576
  // 768
  // 992
  // 1200

  .mainnav {
    box-shadow: inset 0 -2px 0 0 $catskill;
    padding-left: 4.1667%;

    @media (max-width: 1257px) {
      padding-left: 0px;

      .container-fluid {
        padding-left: 0px;
        padding-right: 0px;
      }
    }
  }
}

.top-subnav-bar {
  position: sticky;
  z-index: 500;
  background-color: $catskill-white;
  box-shadow: inset 0 -2px 0 0 $catskill;
}

.dropdown-nav-item {
  padding: 10px 0px;
}

.candidate-search-results {
  overflow-y: auto;
  max-height: 265px;
}

// This set of styles is explicitly handling the
@include media-breakpoint-down(md) {
  .top-nav-bar {
    .mainnav {
      box-shadow: inset 0 -2px 0 0 $catskill;
      padding-left: 0;
    }

    .container {
      padding: 0;
    }
  }

  .mainnav .container-fluid {
    padding: 0;
  }

  .winred-nav {
    .navbar-collapse {
      order: 6;
      .navbar-nav {
        padding-bottom: 0.5rem;

        .nav-link {
          margin-left: 0px;
          margin-right: 0px;
        }

        .active {
          color: $winred-red;
        }

        .navdropdown-title {
          padding: 10px 0px;
        }
      }
    }

    button {
      border-radius: 0;
      min-height: 40px;
      min-width: 50px;
    }

    .navbar-toggle {
      margin: auto 0;
      border-radius: 10px;
      border: 1.5px solid $mystic;
      margin-top: 10px;
      height: 50px;

      &:hover,
      &:active {
        border-color: $nepal;
      }
    }

    button.collapsed {
      background-color: white;
    }

    .dropdown-toggle {
      padding: 0px;

      .navdropdown-title {
        padding: 0px;
        color: rgba(0, 0, 0, 0.5);
      }
    }

    .winred-nav__nav-item {
      box-shadow: 0px 0px;
      padding: 0px 1rem;
      margin-right: 0px;
      margin-left: 0;

      .nav-link {
        background: transparent;
        margin: 0rem 1rem;
        padding-left: 0;
        padding-right: 0;
        color: $pickled-bluewood-light;
      }

      .dropdown-item {
        padding-left: 0px;
        padding-right: 0px;
        color: $mirage;
        background-color: white;
      }

      .dropdown-item + .dropdown-item {
        padding-top: 1rem;
      }

      .dropdown-menu {
        border: 0px;
        background-color: transparent !important;
        padding-left: 1rem;
        padding-right: 1rem;
        margin-top: 0;
      }
    }

    .winred-nav__nav-item--active {
      box-shadow: none; // removes the underline on the active item

      .winred-nav__nav-link {
        color: $pickled-bluewood-light;
      }
    }

    .title-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 0;
    }

    /* Since the container-fluid doesn't have any padding
    to allow the menu to go edge to edge, add some space
    to the first and last item in the navbar so they aren't
    pushed up against the edges. */
    & > :first-child {
      margin-left: 1rem;
    }

    .winred-nav--caret {
      float: right;
    }
    .winred-nav__active-submenu {
      display: flex !important;
      background-color: $catskill-white;
      padding-left: 1rem;

      .nav-link {
        color: $pickled-bluewood;
      }
    }
  }
}

.user-icon-container {
  border: 1px solid $mystic;
  border-radius: 100px;
  padding: 6px 10px;
  margin-left: 10px;
  margin-right: 10px;
}

.navbar-candidate-name {
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (max-width: 991px) {
  .top-nav-bar {
    .navbar-candidate-name {
      max-width: 500px;
    }

    .winred-nav {
      justify-content: center !important;
    }

    .candidate-toggle-icon {
      flex-grow: 0;

      @media (max-width: 991px) {
        flex-grow: 4;
        width: 75%;
      }

      @media (max-width: 594px) {
        width: 50%;
      }
    }

    .menu-box {
      padding-top: 5px;
      padding-bottom: 5px;
      margin-top: 0px !important;
      background-color: $catskill-white;
    }
  }
}
