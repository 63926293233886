@import 'styles/design_system/colors.scss';

// Override Bootstrap CSS
.modal-content {
  border-radius: 4px !important;
  border: 0px !important;
  box-shadow: 0 50px 200px 0 rgba(105, 128, 153, 0.2) !important;
}

.modal-header {
  padding: 1.5rem 1.5rem 0rem !important;
  border-top-left-radius: 4px !important;
  border-top-right-radius: 4px !important;
  border-bottom: 0px !important;

  @media (max-width: 575px) {
    padding: 1rem 1rem 0rem !important;
  }

  .close {
    padding: 0px !important;
    margin: 0px !important;
    margin-top: -9px !important;
    font-size: 35px;
    font-weight: 300;
    color: $nepal;

    &:hover {
      color: $lynx;
    }
  }

  button {
    &:focus {
      outline: 0px;
    }
  }
}

.modal-body {
  padding: 1.5rem !important;
  font-size: 14px !important;
  line-height: 1.4;
  color: $lynx;

  @media (max-width: 575px) {
    padding: 1rem !important;
  }
}

.modal-footer {
  border-top: 0px !important;
  padding: 0rem 1.5rem 1.5rem !important;
  border-bottom-left-radius: 4px !important;
  border-bottom-right-radius: 4px !important;

  @media (max-width: 575px) {
    padding: 0rem 1rem 1rem !important;
  }
}

.confirmation-input {
  padding-bottom: 1rem;
}
