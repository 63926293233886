@import '../../../styles/design_system/colors.scss';

.filter-items-container {
  margin-top: -1rem;
  margin-left: 0px;
}

@media (max-width: 992px) {
  .filter-items-container {
    margin-left: 10px;
  }
}

@media (max-width: 768px) {
  .filter-items-container {
    margin-top: 0rem;
    margin-left: 10px;
  }

  .tag-filter-container {
    margin-right: 14px;
    margin-left: 14px;
  }
}
