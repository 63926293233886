.react-datepicker {
  font-weight: 500;
  border-radius: 4px;
  border: 0px;
  background: white;
  box-shadow: 5px 5px 20px rgba(30, 43, 58, 0.05);
}

.react-datepicker__header {
  color: $lynx;
  background-color: $catskill-white;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border: 1px solid $catskill;
}

.react-datepicker__month {
  color: $lynx;
  border-left: 1px solid $catskill;
  border-right: 1px solid $catskill;
  border-bottom: 1px solid $catskill;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  margin: 0px;
  padding: 0.25rem;
}

.react-datepicker__current-month {
  color: $lynx;
  font-size: 14px;
}

.react-datepicker__day-name {
  color: $lynx;
  font-size: 14px;
}

.react-datepicker__navigation.react-datepicker__navigation--previous {
  border-right-color: $lynx;
}

.react-datepicker__navigation.react-datepicker__navigation--next {
  border-left-color: $lynx;
}

.react-datepicker__day {
  color: $mirage;
  font-size: 14px;
}

.react-datepicker__day--outside-month {
  color: $lynx;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected {
  background-color: $winred-blue !important;
  color: white !important;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range {
  background-color: $winred-blue !important;
  color: white !important;
}

.react-datepicker__day:hover,
.react-datepicker__month-text:hover {
  background-color: $catskill-white;
}