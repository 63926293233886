@import '../../../../styles/design_system/colors.scss';

.page-candidates-tab {
  .candidate-avatar {
    object-fit: cover;
    width: 40px;
    height: 40px;
    border-radius: 100px;
  }

  .add-candidate-icon-container {
    width: 40px;
  }

  .add-text {
    margin-left: 1.3rem !important;
  }
}

.candidate-select-image-container {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  overflow: hidden;
  background: $mystic;
  margin-right: 12px;
  display: flex;
  justify-content: center;
  align-items: center;

  img:not([src]) {
    visibility: hidden;
  }
}

.candidate-select-image {
  object-fit: cover;
  width: 25px;
  height: 25px;
}

.add-candidates-container-card {
  .form-group {
    margin-bottom: 0px;
  }

  .table-input-container {
    p {
      margin-bottom: 0px;
    }
  }
}

.team-pages-committee {
  .main-data-spinner {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

.committee-card {
  .input-dense {
    margin-bottom: 0px !important;
    width: 120px;

    input {
      // Make the input a size so when it appears, it doesn't affect the card height.
      height: calc(1.25em + 1.5rem - 1.5px);
    }
  }

  .toggler {
    margin-right: 1.5rem;
    margin-left: 2rem;

    > div {
      display: block;
    }

    .toggler-subtitle {
      margin-right: 0.75rem;
    }

    .col,
    .toggler-button-container {
      padding: 0px;
    }
  }

  .dense-error-message {
    margin-bottom: calc(-1rem - 5px) !important;
    word-break: break-all;
    width: 120px;
  }
}

@media (max-width: 768px) {
  .committee-card {
    height: auto !important;

    .share-data-toggle {
      margin-top: 1.5rem;
      margin-right: 0rem !important;
      margin-left: 0rem;
      width: 130px;
    }
    .extra-options {
      margin-top: 0.5rem;
      flex-direction: column-reverse;

      .percentage {
        margin-top: 0.5rem;
      }
    }
  }
}
