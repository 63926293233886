@import '../../../../../styles/design_system/colors.scss';

.selected-image {
  transition: background-color 0.4s ease-in-out;
  background-color: $moon-raker !important;
}

.image-upload-drawer {
  background-color: $catskill-white !important;
}
