@import '../../../styles/design_system/colors.scss';

.upsell-sort {
  cursor: grab;
}

.upsell-icon {
  margin: 0.25rem 0.25rem 0.25rem 0rem;
  border-radius: 50%;
  border: 1px solid $catskill;
  background-color: white;

  img {
    height: 2rem;
  }
}

.upsell-icon-cell {
  padding: 0.79rem 0 0.79rem 1.1rem !important;
}

.upsell-icon-container {
  max-height: 45px;
  overflow: hidden;
}

.sub-panel-upsell {
  margin-top: 0 !important;
  border: none;

  .card-body {
    padding-top: 0;
  }
}

.upsell-committee-amount-error {
  font-size: 12px !important;
}

.upsell-donation-amount-input {
  flex-basis: 100%;
}

.amount-1 {
  margin-top: 1rem;
  margin-bottom: 1rem;

  @media (min-width: 576px) {
    padding-right: 0px;
    margin-bottom: 0px;
    margin-top: 0px;
  }
}

.upsell-card-disabled {
  .upsell-card {
    background-color: $catskill-white;

    h4 {
      color: $lynx;
    }

    .upsell-card-image img {
      opacity: 40%;
    }
  }
}

.moneypledge-upsell .image-selector {
  border: 0px;
  .card-body {
    padding: 0px;
  }
}
.upsell-preview-btn {
  padding: 10px 12px;
  font-size: 12px;
}
