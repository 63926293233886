@import '../../../../styles/design_system/colors.scss';

.pre-body {
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: $lynx;
  margin-bottom: 0px;
}

.custom-field-link {
  cursor: pointer;
  font-weight: bold;
  color: $winred-blue-light;
}

.custom-close-btn {
  position: absolute;
  right: 10px;
  top: 25%;
}

.required-asterisk {
  margin-left: 5px;
  color: $winred-red;
}

.add-text {
  margin-left: 1.3rem !important;
}
