.multipier-preview__buttons {
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;

  & > div,
  button {
    width: 22%;
    border-radius: 3px;
    margin-left: 0.3rem;
    margin-right: 0.3rem;
    margin-bottom: 1rem;
  }
}
