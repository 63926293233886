@import '../../styles/design_system/colors.scss';

.list-item-grayed {
  background-color: $catskill-white;
}

.product-table-image {
  padding: 1rem !important;
  img {
    padding: 0;
    width: 100px;
    height: 100px;
    object-fit: contain;
  }
}

.product-table-image-mobile {
  padding: 0rem 0rem 0rem 1rem !important;

  img {
    width: 90%;
    max-width: 100px;
  }
}

.product-table-checkbox-mobile {
  float: right;
}
