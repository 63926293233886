/* Bootstrap class overrides. Put these all here so it's clear that we're
overriding classes provided by bootstrap and these aren't unused classes. */
.card-body {
  padding: 1.5rem;

  @media (max-width: 575px) {
    padding: 1rem;
  }
}

.btn-lg {
  padding: 16px 18px;
}

.dropdown-toggle {
  padding: 0px;

  &:after {
    margin-left: 0rem;
  }
}

.navbar-collapse {
  order: 1;

  box-shadow: inset 0 -2px 0 0 $catskill;
  background: white;

  .navbar-nav {
    width: 100%;
    flex-wrap: wrap;
  }
}

/* Global modal overrides. */
.modal {
  z-index: 50000;
}

.modal-backdrop {
  z-index: 20000;
  background-color: $athens-gray;
}

.modal-backdrop.show {
  opacity: 0.85;
}

.modal-dialog {
  margin: 0rem auto;
  padding: 4rem 1rem 1rem;

  display: flex;
  justify-content: center;
}
