@import '../../styles/design_system/colors.scss';

.tool-tip,
.tooltip-inner {
  background-color: $pickled-bluewood !important;

  &-purple,
  &-purple-inner {
    background-color: $dark-purple !important;
  }

  &-gold,
  &-gold-inner {
    background-color: $whiskey !important;
  }

  &-red,
  &-red-inner {
    background-color: $winred-red !important;
  }

  z-index: 15000;
}

div.tooltip-info > * {
  text-transform: lowercase;
  &:first-letter {
    text-transform: capitalize;
  }
}

.tooltip .arrow {
  display: none;
}

.tooltip-z-index-fix {
  z-index: 20000;
}
