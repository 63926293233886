@import '../../styles/design_system/colors.scss';

.candidate-avatar-container {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  background: $mystic;

  img:not([src]) {
    visibility: hidden;
  }

  .candidate-avatar {
    object-fit: cover;
    width: 40px;
    height: 40px;
    border-radius: 100px;
  }
}
