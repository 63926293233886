@import 'styles/variables';
@import '~bootstrap/scss/bootstrap';

@import '~react-datepicker/dist/react-datepicker.css';
@import '~animate.css/animate.min.css';

@import 'styles/bootstrap-overrides';
@import 'styles/react-datepicker-overrides';

@import 'styles/layout';
@import 'styles/browsers';
@import 'styles/forms';
@import 'styles/data-lists';
@import 'styles/details-page';
@import 'styles/nav-list';
@import 'styles/search-filter';
@import 'styles/tables';
@import 'styles/design_system';
@import 'styles/main';
@import 'styles/dropdown';

@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'styles/utility-classes';

/* General HTML overrides */
* {
  -webkit-font-smoothing: antialiased;
}

body {
  /* override background from modern.scss */
  background: white;
  font-family: 'Roboto', sans-serif !important;

  // dont let modal overwrite body css
  padding-right: 0px !important;
  overflow: initial !important;
}

[role='button'] {
  cursor: pointer;
}

.pointer {
  cursor: pointer !important;
}

.click-disabled {
  cursor: inherit !important;
}

.winred-success-disabled {
  color: white;
  background: #94aac1;
  border-color: #94aac1;
  cursor: auto !important;
  opacity: 0.65;
  box-shadow: none !important;

  &:active,
  &:hover,
  &:focus {
    color: white !important;
    background: #94aac1 !important;
    border-color: #94aac1 !important;
    cursor: auto !important;
    opacity: 0.65;
    box-shadow: none !important;
  }
}

.full-height {
  height: 100%;
}

.full-width {
  width: 100%;
}

.no-table-hover:hover {
  background-color: inherit !important;
}

.create-btn {
  .disabled {
    background-color: $catskill-white !important;
  }

  .btn-group > .btn:not(:first-child) {
    border-bottom-right-radius: 50% !important;
    border-top-right-radius: 50% !important;
  }

  button::after {
    display: none;
  }

  .dropdown-menu {
    border: 1px solid $catskill;
    border-radius: 4px;
    background-color: white;
    padding: 0rem;
    box-shadow: 5px 5px 20px rgba(30, 43, 58, 0.1);
  }

  .dropdown-item {
    background-color: transparent;
    padding: 1rem;
    color: $mirage;

    &:hover {
      background-color: $catskill-white;
    }
  }
}

.image-card-no-border {
  padding-left: 0;
  padding-right: 0;
  padding-top: 0.25rem;

  .card-title {
    margin-bottom: 0rem !important;
  }

  .form-card {
    margin-top: 0;
  }
}

.toggler {
  position: relative;
  cursor: pointer;

  &.toggler-disabled {
    cursor: default !important;
  }

  .toggler-header {
    font-size: 14px;
    font-weight: 500;
    color: $mirage;
    margin-bottom: 0;
  }

  p {
    margin-bottom: 0;
  }
}
.inline-toggler {
  margin-left: 1rem;
  margin-bottom: 0px !important;

  .row {
    align-items: center;
  }

  .toggler-header {
    font-weight: 400 !important;
    color: $lynx;
  }
}

.binary-choice-card {
  padding-bottom: 0.5rem;

  @media (max-width: 575px) {
    padding-bottom: 0rem;
  }

  button {
    margin-bottom: 1rem;
  }
}
.ends-with-field-group-card {
  .form-group {
    &:last-of-type {
      margin-bottom: 0px;
    }
  }
}

.link-button {
  background: none !important;
  border: none;
  padding: 0 !important;
  color: $winred-blue;
  text-decoration: none;
  cursor: pointer;
  outline: none;

  &:hover {
    color: $blue-charcoal;
    text-decoration: underline;
  }
}

.text-underline {
  text-decoration: underline !important;
}

.card-title {
  margin-bottom: 0rem;
}

.no-top-border-radius {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.no-bottom-border-radius {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group:not(.has-validation) > .form-control:not(:last-child) {
  border-top-right-radius: 0.25em;
  border-bottom-right-radius: 0.25em;
}

.empty-list-container {
  max-width: 700px;
  text-align: center;
}

.error-message {
  color: $winred-red;
}

.sub-background {
  background-color: $catskill-white !important;
}

.winred-icon {
  &.winred-icon_sm {
    margin-top: -3px;
    min-width: 18px;
    max-height: 18px;
  }
  &.winred-icon_md {
    margin-top: -3px;
    min-width: 25px;
  }

  &.winred-icon_lg {
    margin-top: -10px;
    min-width: 40px;
  }
}

.text-wrap {
  word-wrap: break-word !important;
}

.no-underline {
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

.underline {
  text-decoration: underline;
}

#root {
  height: 100vh;
}

.border-p1 {
  border-width: 1px;
}
