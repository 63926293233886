@import 'variables';

.page-header {
  background-color: $white;
  box-shadow: inset 0 -2px 0 0 $catskill;
  min-height: 100px;

  .header {
    padding-top: 2rem;
    padding-bottom: 2rem;

    &.transaction-header {
      align-items: flex-start !important;
      flex-direction: column !important;
      justify-content: space-between;
    }

    h1 {
      font-weight: 400;
    }
  }
}

.main-data-spinner {
  align-items: center;
  display: flex;
  justify-content: center;
  padding-top: 80px;
  overflow: hidden;

  &.spinner-overlay,
  &.spinner-fixed {
    z-index: 10000;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.7);
  }

  &.spinner-overlay {
    position: absolute;
  }

  &.spinner-fixed {
    position: fixed;
  }
}

.main-data-error {
  align-items: center;
  display: flex;
  justify-content: center;
  padding-top: 40px;
  flex-direction: column;
  text-align: center;

  .header {
    color: $lynx;
    margin-bottom: 1rem;
  }

  .body {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.detail-content {
  padding: 50px 0;
}

.data-card {
  width: 50%;

  @include media-breakpoint-down('xs') {
    width: 100%;
  }

  .list-group-item {
    display: flex;
  }

  dd,
  dt {
    width: 50%;
  }
}

.card-body {
  padding: 1.5rem;

  @media (max-width: 575px) {
    padding: 1rem;
  }
}

.main-data-empty {
  padding: 3rem;
}

.index-table-container {
  padding: 1rem;
  word-break: break-all;

  @media (max-width: 992px) {
    padding-left: 0px;
    padding-right: 0px;
    overflow-x: hidden;
  }

  @media (max-width: 767px) {
    padding: 0px;
  }
}

.bg-white {
  background-color: $white;
}
