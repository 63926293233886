/* Blocks */

.winred-input {
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.75;
  border: 1px solid $mystic;
  background-color: $white;
  color: $winred-blue;
  outline: none !important;
  box-shadow: none !important;

  &:focus {
    border-color: $nepal;
  }

  &:disabled {
    color: $lynx;
    background-color: $catskill-white;
    border: 1px solid $mystic;
    cursor: default !important;

    &::placeholder {
      color: $lynx;
    }
  }
}

.input-with-prepend {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;

  input {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
}

.input-with-append {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;

  input {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
}

.winred-input-select {
  border: none;

  input {
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
  }

  .float-label .placeholder {
    z-index: 2;
  }

  .winred-input--error_select > div {
    border: 2px solid $winred-red-light !important;
    border-radius: 0.25em;
    background-color: $rose;

    &::placeholder {
      color: $winred-red-light !important;
    }
  }
}

.slug-prepend {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border: 1px solid $mystic;
  border-right: 0px;
  color: $lynx;
  padding: 15px 1rem 11.5px 1rem;
  background-color: $catskill-white;
}

.slug-input {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

/* Modifiers */

.winred-input--active {
  &::-webkit-input-placeholder {
    color: $winred-blue;
  }
  &::-moz-placeholder {
    color: $winred-blue;
  }
  &:-ms-input-placeholder {
    color: $winred-blue;
  }
  &:-moz-placeholder {
    color: $winred-blue;
  }
}

.winred-input--pro {
  color: $white;
  background-color: $pickled-bluewood;
  border: 1px solid $pickled-bluewood-light;
  &:focus {
    color: $white;
    background-color: $pickled-bluewood;
  }
  &:disabled {
    color: $lynx;
    background-color: $pickled-bluewood;
    border: 1px solid $pickled-bluewood-light;
  }
  &::-webkit-input-placeholder {
    color: $white;
  }
  &::-moz-placeholder {
    color: $white;
  }
  &:-ms-input-placeholder {
    color: $white;
  }
  &:-moz-placeholder {
    color: $white;
  }
}

.winred-input--disabled {
  color: $lynx;
  background-color: $white;
  border: 1px solid $mystic;
}

.winred-input--error {
  border: 2px solid $winred-red-light !important;
  border-radius: 0.25em;
  background-color: $rose !important;

  input {
    background-color: $rose !important;
  }

  &::placeholder {
    color: $winred-red-light !important;
  }
}

.error-border {
  border: 1px solid $winred-red-light !important;
}

.winred-input--warning {
  border: 1px solid $rajah;

  &::-webkit-input-placeholder {
    color: $pickled-bluewood-light;
  }
  &::-moz-placeholder {
    color: $pickled-bluewood-light;
  }
  &:-ms-input-placeholder {
    color: $pickled-bluewood-light;
  }
}

.winred-input--disabled--pro {
  color: $lynx;
  background-color: $pickled-bluewood;
}

.winred-input--rounded {
  border-radius: 25px;
}
