@import '../../../styles/design_system/colors.scss';

.dropdown-item {
  align-items: center;
}

.winred-dropdown-icon {
  display: flex;
  align-items: center;
  justify-content: center;

  .winred-icon {
    max-width: 1.6rem;
  }
}

.create-report {
  font-size: 1.25rem;
}

.custom-report-card {
  .create-report {
    height: 2.5rem;
    min-width: unset;
    width: 2.5rem;
  }
}

.custom-report-card-container {
  margin-bottom: 1rem;
  height: calc(100% - 1rem);

  .custom-report-card {
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 100%;

    .card-body {
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    .custom-report-list-card-value {
      word-break: normal;
    }
  }
}

.custom-report-card .card-body > div:nth-last-child(2) {
  border-bottom: none;
  flex-grow: 1;
}

.custom-reports-list {
  .custom-report-list-border {
    border-bottom: 1px solid $catskill;
  }

  .custom-report-card .card-body > div:nth-last-child(2) {
    border-bottom: none;
    flex-grow: 1;
  }

  .custom-report-card .card-body {
    width: 100%;
  }

  .custom-report-list-card-title {
    width: 30%;
  }

  .custom-report-list-card-value {
    word-break: unset;
    width: 70%;
  }
}

.custom-report-header-container {
  display: flex;
  align-items: center;
  padding: 0.3rem;

  img {
    margin-right: 0.5rem;
    height: 40px;
    width: 40px;
  }
}

.custom-report-card-container {
  margin-bottom: 1rem;
  height: calc(100% - 1rem);

  .custom-report-card {
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 100%;

    .card-body {
      display: flex;
      flex-direction: column;
      height: 100%;
      width: 100%;
    }
  }
}

.custom-report-card .card-body > div:nth-last-child(2) {
  border-bottom: none;
  flex-grow: 1;
}