/* Utility classes */
.flex-1 {
  flex: 1;
}

.scroll-x {
  overflow-x: auto;
}

.scroll-y {
  overflow-y: auto;
}

.overflow-hidden-x {
  overflow-x: hidden;
}

.overflow-hidden-y {
  overflow-y: hidden;
}

.font-roboto {
  font-family: 'Roboto', sans-serif;
}

.pointer {
  cursor: pointer !important;
}
.click-disabled {
  cursor: inherit !important;
}
.cursor-grab {
  cursor: grab;
}
.cursor-hover-pointer:hover {
  cursor: pointer;
}

.reset-cursor {
  cursor: default !important;
}

.word-wrap {
  word-break: break-all;
}
.text-wrap {
  word-wrap: break-word !important;
}

.border-1 {
  border-width: 0.1rem;
}

.border-1 {
  border-width: 0.15rem;
}

.no-outline {
  outline: none;

  &:focus,
  &:active {
    outline: none;
  }
}

.tracking-widest {
  letter-spacing: 2px;
}

$fontSizes: (
  'tiny': 10px,
  'smaller': 0.75rem,
  'small': 14px,
  'normal': 1rem,
  'large': 1.25rem,
);

@each $name, $size in $fontSizes {
  .font-#{'' + $name} {
    font-size: $size;
  }
}
@each $name, $size in $spacers {
  .leading-#{'' + $name} {
    line-height: $size;
  }
}
.leading-none {
  line-height: 1;
}

$custom-sizes: (
  '6': 4rem,
  '18': 12rem,
  '19': 13rem,
  '21': 15rem,
);

// Use both the bootstrap builtin sizes and our custom sizes.
@each $name, $size in map-merge($spacers, $custom-sizes) {
  .height-#{'' + $name} {
    height: $size;
  }
  .min-height-#{'' + $name} {
    min-height: $size;
  }
  .width-#{'' + $name} {
    width: $size;
  }
  .max-width-#{'' + $name} {
    max-width: $size;
  }
  .min-width-#{'' + $name} {
    min-width: $size;
  }
}

$boxShadowColors: (
  'catskill': $catskill,
  'winred-red': $winred-red,
  'winred-blue-light': $winred-blue-light,
  'nepal': $nepal,
  'pickled-bluewood-light': $pickled-bluewood-light,
  'pickled-bluewood': $pickled-bluewood,
);

@each $name, $color in $boxShadowColors {
  .box-shadow-inset-#{'' + $name} {
    box-shadow: inset 0 -2px 0 0 $color;
  }
}
