.details-page {
  .info-list-label {
    text-transform: capitalize;
    width: 40%;
  }

  .image-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, 150px);
    grid-gap: 1rem;
  }

  .grid-image-item {
    max-width: 150px;
    max-height: 135px;
    object-fit: cover;
    object-position: center;
  }

  .richtext-container {
    width: 75%;
    @media (max-width: 575px) {
      width: 100%;
    }
  }

  .info-list-value {
    width: 60%;
  }

  ol,
  ul,
  dl {
    margin-top: 1em !important;
  }

  .detail-card-container {
    -webkit-column-break-inside: avoid;
    page-break-inside: avoid;
    break-inside: avoid;

    .detail-card {
      word-break: break-word;

      .list-group {
        border-top: none;
      }

      .information-list-card {
        padding: 0;

        .card-title {
          padding: 1.5rem 1.5rem 0.1rem 1.5rem;
        }
        .winred-card-subtitle {
          padding-left: 1.5rem;
        }
      }

      .list-group-title {
        padding-top: 1.5rem;
        padding-bottom: 0.1rem;
        padding-left: 1.5rem !important;
        padding-right: 1.5rem !important;

        @media (max-width: 575px) {
          padding-left: 1rem !important;
          padding-right: 1rem !important;
        }
      }

      .list-group-item {
        flex-direction: row;
        // flex-direction: column;
        display: flex;
        justify-content: flex-start;
        padding: 1rem 1.5rem;
        border: 0px solid $catskill;
        margin-bottom: 0px;
        border-top: 1px solid $catskill;

        @media (max-width: 575px) {
          padding: 1rem;
        }
      }
      dt {
        width: 50%;
        margin-right: 10px;
      }
      dd {
        width: 100%;
        margin-bottom: 0px;
      }
      .no-overflow {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .payment-details {
        background-color: $winred-blue !important;
        background-image: url('../assets/images/card-background.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        padding: 15px;
        color: $white;
        width: 100%;
        max-width: 240px;
        border: 0px;
        border-radius: 10px;
        font-family: Consolas, 'Lucida Console', Monaco, monospace;
        text-transform: uppercase;
        box-shadow: 5px 5px 20px rgba(30, 43, 58, 0.05);

        .card-logo {
          width: 54px;
          display: block;
          margin: -3px -3px 30px auto;
        }

        .card-brand {
          opacity: 0.75;
          float: right;
          margin-bottom: -21px;
        }

        .svg-inline--fa.fa-w-18 {
          width: 48px;
          display: block;
        }

        .card-value {
          position: relative;
          top: 2px;
        }

        .card-field {
          opacity: 0.75;
          font-size: 12px;
        }

        .list-group-item {
          background-color: transparent;
          padding: 0px;
          border: 0px;
        }

        .list-group-item:first-child {
          align-items: flex-end;

          dd:last-child {
            text-align: center;
          }
        }
      }
    }
  }
}

.timeline {
  width: 100%;

  & > div:not(:first-child) {
    margin: 0px !important;
    padding: 8px 0px 10px 25px !important;
  }

  & > div:last-child {
    display: none !important;
  }
}

.payout-details {
  column-count: 1;
}
