@import '../../../../styles/design_system/colors.scss';

.donation-amounts {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 0;

  .donation-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
    width: 100%;

    .position-amount {
      display: flex;
      max-width: 125px;
      padding-left: 0.5rem;
      padding-right: 0.5rem;
      margin-right: 1rem;
      justify-content: space-around;
      color: black;
    }

    .donation-amount {
      flex: 1;

      position: relative;
      padding: 0.25rem 0rem 0rem 0rem;
      justify-content: space-around;
      align-items: space-around;

      .winred-input--active,
      .winred-input {
        /* Don't show the up and down button */
        &[type='number'] {
          border-radius: 0.25rem;
          -moz-appearance: textfield; /* Firefox */
        }
      }

      .form-group {
        margin-bottom: 0px;
      }
    }
    
    .error-message {
      display: flex;
      flex-direction: row;
      align-items: center;

      p {
        margin-left: 0.5rem;
        margin-bottom: 0px;
      }
    }
  }

  #addField {
    flex: 1;
    display: flex;
    align-items: center;
    padding: 1rem;
    cursor: pointer;
  }
}
